import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent {
  isModalOpen = false;
  isSubmitting = false;
  contactForm: FormGroup;
  expandedCardId: string | null = null;
  readonly RECIPIENT_EMAIL = 'kumarrajesh@leanqubit.ai'; 
//   readonly RECIPIENT_EMAIL = 'sshah@optebiz.com'; 


  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: [''],
      company: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  openContactPopup(): void {
    this.isModalOpen = true;
    document.body.style.overflow = 'hidden';
  }

  closeModal(): void {
    this.isModalOpen = false;
    document.body.style.overflow = 'auto';
    this.contactForm.reset();
  }

  closeModalOnOverlay(event: MouseEvent): void {
    if ((event.target as HTMLElement).className === 'modal-overlay') {
      this.closeModal();
    }
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      
      try {
        const formData = this.contactForm.value;
        const emailSubject = `New Contact Request from ${formData.name} - LeanQubit INC`;
        
        // Create HTML email body with professional formatting
        const emailBody = `
  Dear Team,
  
  A new contact form submission has been received from the LeanQubit INC website.
  
  Contact Details:
  ------------------------------------------
  Name: ${formData.name}
  Email: ${formData.email}
  Phone: ${formData.mobile || 'Not provided'}
  Company: ${formData.company}
  
  Message:
  ------------------------------------------
  ${formData.message}
  
  ------------------------------------------
  
  
  Best regards,
  LeanQubit INC
  www.leanqubit.ai
  
        `;
  
        // Create Outlook Web URL with formatted body
        const outlookUrl = `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(this.RECIPIENT_EMAIL)}&subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
  
        window.open(outlookUrl, '_blank');
  
        this.toastr.success('Composing email in Outlook Web', 'Success', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
          progressBar: true
        });
  
        this.isSubmitting = false;
        this.closeModal();
      } catch (error) {
        this.toastr.error('An error occurred while opening Outlook Web. Please try again.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-top-right',
          closeButton: true,
          progressBar: true
        });
        this.isSubmitting = false;
      }
    }
  }

  toggleCard(cardId: string): void {
    if (this.expandedCardId === cardId) {
      this.expandedCardId = null;
    } else {
      this.expandedCardId = cardId;
    }
  }
}
<section class="services-area">
    <div class="container">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subtitle }}</span
            >
            <h2
                class="wow animate__animated animate__fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
                style="color: #30307f"
            >
                <span
                    style="
                        background: -webkit-linear-gradient(
                            rgb(4, 79, 103),
                            rgb(230, 95, 50)
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    "
                >
                    Transforming </span
                >{{ sectionTitleContent.title }}
            </h2>
            <p
                style="
                    font-weight: 400;
                    font-family: Condensed;
                    font-size: 18px;
                    line-height: 30px;
                    color: #4e5f6d;
                    max-width: 935px;
                    margin: 7px auto 32px;
                "
            >
                {{ sectionTitleContent.paragraphText }}
            </p>
        </div>
        <div class="row">
            <div
                class="col-lg-4 col-md-6 col-sm-12"
                *ngFor="let ServicesBoxContent of singleServicesBoxItem"
            >
                <div
                    class="single-services-box-item"
                    style="height: 500px; display: flex; flex-direction: column"
                >
                    <div class="icon">
                        <img [src]="ServicesBoxContent.icon" alt="image" />
                    </div>
                    <h3>
                        <a>{{ ServicesBoxContent.title }}</a>
                    </h3>
                    <div style="flex-grow: 1; overflow-y: auto">
                        <p
                            style="
                                font-weight: 500;
                                font-family: Nunito, sans-serif;
                                font-size: 15px;
                                line-height: 24px;
                                max-width: 350px;
                                padding-bottom: 15px;
                                color: #333;
                            "
                        >
                            {{ ServicesBoxContent.paragraphText }}
                        </p>
                    </div>
                    <a
                        routerLink="/{{ ServicesBoxContent.link }}"
                        class="learn-more-btn"
                        style="margin-top: auto"
                        ><i></i>{{ ServicesBoxContent.linkText }}&nbsp;&nbsp;<i
                            class="{{ ServicesBoxContent.linkIcon }}"
                        ></i
                    ></a>

                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

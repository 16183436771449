<footer class="footer-section">
    <div class="container">
        <div class="footer-content pt-5 pb-5">
            <div class="row justify-content-between">
                <div class="col-xl-3 col-lg-3 mb-50 px-3">
                    <!-- Added px-3 for padding -->
                    <div class="footer-widget">
                        <div class="footer-logo">
                            <a href="index.html">
                                <img
                                    src="assets/img/LeanQbit_logo.png"
                                    alt="logo"
                                    style="width: 150px; height: 80px"
                                    class="img-fluid"
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <div class="footer-text">
                            <p style="font-family: Condensed">
                                LeanQubit is a dynamic company at the forefront
                                of the industrial revolution 4.0 in
                                manufacturing, specializing in MES-based
                                solutions, CloudConnect, AWS, and Inductive
                                Automation
                            </p>
                        </div>
                        <div class="footer-social-icon">
                            <span>Follow us</span>
                            <a
                                href="https://www.linkedin.com/company/optebiz-pvt-ltd-/mycompany/"
                            >
                                <i
                                    class="bx bxl-linkedin-square facebook-bg"
                                ></i>
                            </a>
                            <a href="https://twitter.com/optebiz?lang=en">
                                <i class="bx bxl-twitter twitter-bg"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-6 mb-30 px-3">
                    <!-- Added px-3 for padding -->
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Get in touch with us</h3>
                        </div>
                        <div class="single-cta">
                            <i class="bx bxs-phone"></i>
                            <div class="cta-text">
                                <h4>Call us</h4>
                                <span
                                    ><a href="tel:+1 (419) 476-4520"
                                        >+1 (419) 476-4520</a
                                    ></span
                                >
                            </div>
                        </div>
                        <div class="single-cta">
                            <i class="bx bx-envelope"></i>
                            <div class="cta-text">
                                <h4>Mail us</h4>
                                <span
                                    ><a href="mailto:sales@leanqubit.ai"
                                        >sales@leanqubit.ai</a
                                    ></span
                                >
                            </div>
                        </div>
                        <div class="single-cta">
                            <i class="bx bxs-map"></i>
                            <div class="cta-text">
                                <h4>US Office</h4>
                                <span>
                                    LeanQubit Inc.<br />
                                    2600 Dorr Street<br />
                                    Toledo, OH 43606
                                </span>
                            </div>
                        </div>
                        <div class="single-cta">
                            <i class="bx bxs-map"></i>
                            <div class="cta-text">
                                <h4>India Office</h4>
                                <span>
                                    LeanQubit Private Limited<br />
                                    B-205, Mastermind III, Royal Palms IT
                                    Park<br />
                                    Aarey Colony, Goregaon (East) Mumbai,
                                    Maharashtra, IN<br />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-6 mb-30 px-3">
                    <!-- Added px-3 for padding -->
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Explore</h3>
                        </div>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="/about-us">About us</a></li>
                            <li><a href="/Facto-Tool-Kit">Facto toolkit</a></li>
                            <li>
                                <a href="/Client-Testimonials">Testimonials</a>
                            </li>
                            <li><a href="/BookDemo">Contact</a></li>
                            <li><a href="/faq">FAQ</a></li>
                            <li>
                                <a href="/inductive-details"
                                    >Inductive Automation</a
                                >
                            </li>
                            <li><a href="#">Amazon Web Service(Saas)</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 mb-30 px-3">
                    <!-- Added px-3 for padding -->
                    <div class="footer-widget subscribe-widget">
                        <div class="footer-widget-heading">
                            <h3>Subscribe</h3>
                        </div>
                        <div class="subscribe-form">
                            <input
                                type="email"
                                placeholder="Enter your email"
                            />
                            <button type="submit">
                                <i class="bx bx-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div
                    class="col-xl-6 col-lg-6 text-center text-lg-left d-flex align-items-center"
                >
                    <div class="copyright-text">
                        <p>
                            Copyright &copy; 2023, All Right Reserved
                            <a href="/">LeanQubit Private Limited</a>
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                    <div class="footer-menu">
                        <ul style="margin-left: 149px">
                            <li><a href="/Terms&Condition">Terms</a></li>
                            <li><a href="/privacy&policy">Privacy</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<a href="BookDemo" class="ask-us-button">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="button-icon"
    >
        <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H9c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"
        />
    </svg>
    <span class="button-text">Ask Us</span>
</a>
<div class="go-top"><i class="flaticon-up"></i></div>

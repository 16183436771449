<div class="cs_content">
    <section class="cs_hero cs_style_1 position-relative" id="home">
        <div class="container" style="max-width: 1300px">
            <div class="cs_hero_text text-center">
                <h1
                    class="cs_hero_title cs_text_white wow fadeInUp"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.2s"
                >
                    Manufacturing, Reimagined Where Data-Driven Insights Meet
                    Operational Excellence
                </h1>
                <p
                    class="cs_hero_subtitle wow fadeIn"
                    data-wow-duration="0.8s"
                    data-wow-delay="0.25s"
                >
                    Discover data-driven insights to optimize production,
                    efficiency, and growth
                </p>
            </div>
            <div
                class="cs_btn_group text-center wow fadeIn"
                data-wow-duration="0.8s"
                data-wow-delay="0.25s"
            >
                <a href="BookDemo" class="cs_btn cs_bg_white">Product Demo</a>
                <a href="TryItNow" class="cs_btn cs_bg_accent"
                    >Start Free Trial</a
                >
            </div>
            <div class="cs_height_100 cs_height_lg_60"></div>
            <div class="image-carousel-container">
                <div class="navigation-icon prev" (click)="prevImage()">
                    <i class="fas fa-chevron-left"></i>
                </div>

                <div class="carousel-image" [@uniqueImageTransition]>
                    <img
                        [src]="images[currentImageIndex].src"
                        alt="Carousel Image"
                    />
                </div>

                <div class="navigation-icon next" (click)="nextImage()">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
        <div class="cs_hero_shape1"></div>
        <div class="cs_hero_shape2">
            <img src="assets/img/OptebizImage/Polygon.svg" alt="Polygon Icon" />
        </div>
        <div class="cs_hero_shape3"></div>
    </section>
</div>

<!-- second section -->
<section class="cs_business_feature position-relative" id="features">
    <div class="cs_height_143 cs_height_lg_75"></div>
    <div class="container" style="max-width: 1300px">
        <div class="cs_section_heading cs_style_1 text-center">
            <p
                class="cs_section_subtitle cs_text_accent wow fadeInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
            >
                Unlock the Full Potential of Your Manufacturing Operations
            </p>
            <h2
                class="cs_section_title mb-0 wow fadeIn"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
            >
                Harness the power of production intelligence to drive informed
                decisions and business success.Transform your manufacturing
                operations with LeanQubit's innovative solutions and expert
                guidance
            </h2>
        </div>
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img
                            src="assets/img/OptebizImage/shop.svg"
                            alt="Icon"
                        />
                    </div>
                    <h3 class="cs_iconbox_title">Scalable Solutions</h3>
                    <p class="cs_iconbox_subtitle">
                        A comprehensive suite of tools tailored to meet the
                        unique demands of diverse manufacturing environments,
                        enabling enhanced operational efficiency and seamless
                        automation.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img
                            src="assets/img/OptebizImage/price_bar.svg"
                            alt="Icon"
                        />
                    </div>
                    <h3 class="cs_iconbox_title">Cost-Effective Automation</h3>
                    <p class="cs_iconbox_subtitle">
                        Streamline workflows, reduce downtime, and cut
                        operational costs with innovative solutions that drive
                        productivity and deliver long-term value to your
                        business.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img src="assets/img/OptebizImage/ui.svg" alt="Icon" />
                    </div>
                    <h3 class="cs_iconbox_title">Seamless Integration</h3>
                    <p class="cs_iconbox_subtitle">
                        Experience effortless deployment with advanced
                        automation tools designed for quick setup and minimal IT
                        complexity, allowing for a smooth transition.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img
                            src="assets/img/OptebizImage/setup.svg"
                            alt="Icon"
                        />
                    </div>
                    <h3 class="cs_iconbox_title">Real-Time Insights</h3>
                    <p class="cs_iconbox_subtitle">
                        Leverage intuitive dashboards to gain unparalleled
                        visibility into operations, empowering informed
                        decisions and improving overall performance.
                    </p>
                </div>
            </div>
        </div>
        <div class="cs_featured_shape">
            <img src="assets/img/OptebizImage/Vector.svg" alt="Image" />
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>

<div class="m2-fixed-container">
    <div class="carousel-section">
        <div class="carousel-section-header">
            <h2>
                Unlock Operational Excellence with
                <strong
                    ><span style="color: #1d9395">Lean</span
                    ><span style="color: #da5c30">Q</span
                    ><span style="color: #1d9395">ubit</span></strong
                >
            </h2>
        </div>
        <div class="slides-container">
            <div
                *ngFor="let slide of slides; let i = index"
                class="slide"
                [class.active]="currentSlideIndex === i"
                [id]="slide.id"
            >
                <div class="content">
                    <span class="step-counter">{{ slide.stepCounter }}</span>
                    <h5 [innerHTML]="slide.title"></h5>
                    <p>{{ slide.description }}</p>
                </div>
                <div class="lottie-container" [id]="'animation-' + (i + 1)">
                    <div class="image-container">
                        <ng-container *ngIf="slide.mediaType === 'video'">
                            <video
                                [src]="slide.mediaSource"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                "
                                autoplay
                                loop
                                muted
                            >
                                Your browser does not support the video tag.
                            </video>
                        </ng-container>
                        <ng-container *ngIf="slide.mediaType === 'image'">
                            <img
                                [src]="slide.mediaSource"
                                alt="Description"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                "
                            />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation-arrows progress-bars">
            <button
                class="nav-arrow prev"
                [disabled]="currentSlideIndex === 0"
                (click)="prevSlide()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M14.5 17L9.5 12L14.5 7"
                        stroke="#FFFFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
            <div class="progress-bar-inner">
                <div
                    *ngFor="let slide of slides; let i = index"
                    class="progress-bar"
                >
                    <div
                        class="progress-bar-fill"
                        [style.width.%]="getProgressWidth(i)"
                    ></div>
                </div>
            </div>
            <button
                class="nav-arrow next"
                [disabled]="currentSlideIndex === slides.length - 1"
                (click)="nextSlide()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M9.5 7L14.5 12L9.5 17"
                        stroke="#FFFFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
        </div>
    </div>
</div>

<!-- new section -->
<div class="m4-features-main-container noribbon">
    <div class="m2-fixed-containernew">
        <h2>Configurable. Flexible. Extensible.</h2>
        <div class="m4-features-items-cont">
            <div class="m4-features-item">
                <div class="m4-features-item-text">
                    <h3>Gold Certified Ignition Partner</h3>
                    <div class="m4-fet-text">
                        <p>
                            Empowering manufacturing innovation, LeanQubit
                            delivers seamless digitization as a Gold Certified
                            Ignition Partner.
                        </p>
                        <p>
                            LeanQubit enhances operational value through the
                            strategic implementation of Ignition® by Inductive
                            Automation.
                        </p>
                    </div>
                    <div class="m4-fet-cta">
                        <a
                            href="inductive-details"
                            contenteditable="false"
                            style="cursor: pointer"
                            >Learn More</a
                        >
                    </div>
                </div>
                <div class="m4-features-image-box">
                    <img
                        class="gold-badge"
                        src="assets/img/OptebizImage/GoldCertified.svg"
                        alt="integrations-header-devices"
                        loading="lazy"
                        style="max-width: 100%"
                    />
                </div>
            </div>

            <div class="m4-features-item">
                <div class="m4-features-item-text">
                    <h3>Automate with Expertise</h3>
                    <div class="m4-fet-text">
                        <p>
                            LeanQubit brings over 25+ years of expertise to
                            provide customized MES solutions.
                        </p>
                        <p>
                            Discover how our cutting-edge dashboard empowers
                            businesses to make data-driven decisions.
                        </p>
                    </div>
                    <div class="m4-fet-cta">
                        <a
                            href="inductive-details"
                            contenteditable="false"
                            style="cursor: pointer"
                            >Explore Expertise</a
                        >
                    </div>
                </div>
                <div class="m4-features-image-box">
                    <img
                        src="assets/img/OptebizImage/Downloads.png"
                        alt="mm21_integrations_iso_R1 Copy 5"
                        loading="lazy"
                        style="max-width: 100%; height: auto"
                    />
                </div>
            </div>

            <div class="m4-features-item">
                <div class="m4-features-item-text">
                    <h3>Unlocking Potential with AWS</h3>
                    <div class="m4-fet-text">
                        <p>
                            As a certified Ignition integrator and an accredited
                            AWS partner, we guarantee seamless, scalable, and
                            secure automation for your business.
                        </p>
                        <p>
                            Discover how our cutting-edge dashboard empowers
                            businesses to make data-driven decisions.
                        </p>
                    </div>
                    <div class="m4-fet-cta">
                        <a
                            href="aws"
                            contenteditable="false"
                            style="cursor: pointer"
                            >Unlocking AWS</a
                        >
                    </div>
                </div>
                <div class="m4-features-image-box">
                    <img
                        src="assets/img/OptebizImage/cloud-partner-aws-1024x811_Update1.png"
                        alt="mm20-laptop_0004_Extensibility-Utilization_dropshadow"
                        loading="lazy"
                        style="max-width: 100%; height: auto"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- fourth section -->

<section
    id="pricing"
    class="cs_section_shape_wrap_1 cs_gradient_bg_1 position-relative overflow-hidden"
>
    <div class="cs_section_shape_1 position-absolute">
        <img src="assets/img/OptebizImage/Vector3.svg" alt="Shape" />
    </div>
    <div class="cs_section_shape_2 position-absolute">
        <img src="assets/img/OptebizImage/dot.svg" alt="Shape" />
    </div>
    <div class="cs_height_143 cs_height_lg_75"></div>
    <div class="container" style="max-width: 1300px">
        <div class="cs_section_heading cs_style_1 cs_style_1 text-center">
            <p
                class="cs_section_subtitle cs_text_accent wow fadeInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
            >
                Our Pricing
            </p>
            <h2
                class="cs_section_title mb-0 wow fadeIn"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
            >
                Find the Perfect Fit for Your Needs
            </h2>
        </div>
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-4">
                <div class="cs_pricing_table cs_style_1">
                    <div class="cs_pricing_head">
                        <div class="cs_pricing_icon">
                            <img
                                src="assets/img/OptebizImage/symbol.svg"
                                alt="Image"
                            />
                        </div>
                        <div class="cs_pricing_head_text">
                            <h3 class="cs_pricing_head_title">Basic</h3>
                            <p class="cs_pricing_head_subtitle mb-0">
                                For small business
                            </p>
                        </div>
                    </div>
                    <div class="cs_pricing_seperator"></div>
                    <div class="cs_pricing_plan"></div>
                    <div class="cs_pricing_feature">
                        <ul class="cs_pricing_feature_list cs_mp0">
                            <li>
                                <i class="fa-solid fa-check"></i>Ignition
                                Installation & Licensing Assistance
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Basic System
                                Architecture Design
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Connecting to
                                Devices
                            </li>
                            <li>
                                <i class="fa-solid fa-xmark"></i>Ignition
                                Starter Training
                            </li>
                            <li>
                                <i class="fa-solid fa-xmark"></i>Basic
                                Troubleshooting & Support
                            </li>
                            <li>
                                <i class="fa-solid fa-xmark"></i>Access to
                                Resources
                            </li>
                        </ul>
                        <a href="BookDemo" class="cs_pricing_btn cs_up_3_hover"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="cs_pricing_table cs_style_1">
                    <div class="cs_pricing_head">
                        <div class="cs_pricing_icon">
                            <img
                                src="assets/img/OptebizImage/symbol1.svg"
                                alt="Image"
                            />
                        </div>
                        <div class="cs_pricing_head_text">
                            <h3 class="cs_pricing_head_title">Intermediate</h3>
                            <p class="cs_pricing_head_subtitle mb-0">
                                For sophisticated industrial
                            </p>
                        </div>
                    </div>
                    <div class="cs_pricing_seperator"></div>
                    <div class="cs_pricing_plan"></div>
                    <div class="cs_pricing_feature">
                        <ul class="cs_pricing_feature_list cs_mp0">
                            <li>
                                <i class="fa-solid fa-check"></i>Custom SCADA
                                Dashboards
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>IIoT Device
                                Integration
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Database
                                Integration & Historian Setup
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Custom
                                Scripting & Automation
                            </li>
                            <li>
                                <i class="fa-solid fa-xmark"></i>Industrial
                                Protocol Integration
                            </li>
                            <li>
                                <i class="fa-solid fa-xmark"></i>Security
                                Hardening
                            </li>
                            <li>
                                <i class="fa-solid fa-xmark"></i>Team Training
                            </li>
                        </ul>
                        <a href="BookDemo" class="cs_pricing_btn cs_up_3_hover"
                            >Book Now</a
                        >
                    </div>
                    <!-- <div class="cs_pricing_badge">Most Popular</div> -->
                </div>
            </div>
            <div class="col-lg-4">
                <div class="cs_pricing_table cs_style_1">
                    <div class="cs_pricing_head">
                        <div class="cs_pricing_icon">
                            <img
                                src="assets/img/OptebizImage/symbol3.svg"
                                alt="Image"
                            />
                        </div>
                        <div class="cs_pricing_head_text">
                            <h3 class="cs_pricing_head_title">Advanced</h3>
                            <p class="cs_pricing_head_subtitle mb-0">
                                For Enterprise-grade solutions
                            </p>
                        </div>
                    </div>
                    <div class="cs_pricing_seperator"></div>
                    <div class="cs_pricing_plan"></div>
                    <div class="cs_pricing_feature">
                        <ul class="cs_pricing_feature_list cs_mp0">
                            <li>
                                <i class="fa-solid fa-check"></i>End-to-End
                                Ignition Solution Design
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Advanced IIoT
                                Implementation
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Complex SCADA
                                Development
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Advanced
                                Historian Setup & Data Analytics
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Redundancy
                                Gateways & High Availability
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Custom IIoT
                                Applications
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>Training &
                                Support for Advanced Teams
                            </li>
                            <li>
                                <i class="fa-solid fa-check"></i>System
                                Optimization & Maintenance
                            </li>
                        </ul>
                        <a href="BookDemo" class="cs_pricing_btn cs_up_3_hover"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>

<section>
    <div class="container" style="max-width: 1300px; margin-bottom: 50px">
        <div class="cs_cta cs_style_1 text-center position-relative">
            <div class="cs_cta_in">
                <div class="cs_section_heading cs_style_1">
                    <h2
                        class="cs_section_title cs_text_white"
                        style="
                            font-size: 50px;
                            line-height: 1.3em;
                            position: relative;
                            z-index: 2;
                        "
                    >
                        Upgrade Your Business with Our <br />
                        Cutting-Edge POS Solutions!
                    </h2>
                    <p
                        class="cs_section_subtitle mb-0"
                        style="
                            font-weight: 600;
                            margin-bottom: 10px;
                            font-size: 18px;
                            line-height: 1.6em;
                        "
                    >
                        Experience the future of transactions! Schedule a demo
                        today and witness how <br />
                        our POS solution can revolutionize your business.
                    </p>
                </div>
                <div class="cs_btn_group text-center">
                    <a href="TryItNow" class="cs_btn cs_bg_white"
                        >Start Free Trial</a
                    >
                    <a href="BookDemo" class="cs_btn cs_bg_accent"
                        >Purchase Now</a
                    >
                </div>
            </div>
            <div class="cs_cta_shape1">
                <img
                    src="assets/img/OptebizImage/Ellipse_small.svg"
                    alt="Image"
                />
            </div>
            <div class="cs_cta_shape2">
                <img src="assets/img/OptebizImage/stroke.svg" alt="Image" />
            </div>
        </div>
    </div>
</section>

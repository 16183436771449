import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    
   siteKey:string;
   submitted = false;
   clicked = false;

   form: FormGroup;
   emailBody: string = '';
    
   constructor(private fb: FormBuilder) {
       this.form = this.fb.group({
           from_name: ['', Validators.required],
           from_email: ['', [Validators.required, Validators.email]],
           contact: ['', Validators.required],
           subject: ['', Validators.required],
           message: ['']
       });
   }

   
    
   
   
    ngOnInit(): void {
      
    }

    
    pageTitleArea: pageTitle[] = [
        {
            title: 'Contact Us'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Address',
            location: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
        }
    ]
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'Contact',
            number: '+1(419)476-4520',
            email: 'bizops@OpteBiz.com'
        }
    ]
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Hours of Operation',
            text1: 'Monday - Friday: 10:00 - 20:00',
            text2: 'Alternate Saturday: 00:09 - 13:00'
        }
    ]

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Get In Touch",
            title: 'Ready to Get Started?',
            paragraphText: 'Your email address will not be published. Required fields are marked *'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/OptebizImage/contact.png'
        }
    ]

    

    send() {
        if (this.form.valid) {
            const { from_name, from_email, contact, subject, message } = this.form.value;
            
            // Format email body
            const emailBody = `
Name: ${from_name}
Email: ${from_email}
Contact: ${contact}

Message:
${message || 'No message provided'}
            `.trim();
            
            // Create URLs for different email clients
            const outlookUrl = `ms-outlook://compose?to=bizops@OpteBiz.com&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
            const webOutlookUrl = `https://outlook.office.com/mail/deeplink/compose?to=bizops@OpteBiz.com&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
            const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=bizops@OpteBiz.com&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
            
            // Try to open email clients in sequence
            this.tryOpenEmailClient(outlookUrl, webOutlookUrl, gmailUrl);
            
            // Reset form
            this.form.reset();
        }
    }

    private tryOpenEmailClient(...urls: string[]) {
        const tryOpen = (url: string): boolean => {
            const opened = window.open(url, '_blank');
            return opened !== null;
        };

        // Try each URL in sequence until one works
        for (const url of urls) {
            if (tryOpen(url)) {
                return;
            }
        }

        // If all fail, use regular mailto as final fallback
        const mailtoUrl = `mailto:bizops@OpteBiz.com?subject=${encodeURIComponent(this.form.value.subject)}&body=${encodeURIComponent(this.emailBody)}`;
        window.location.href = mailtoUrl;
    }

    private detectEmailClient(): string {
        // Check if running in desktop Outlook (this is a basic check)
        const isOutlookDesktop = /MSOutlook/i.test(navigator.userAgent);
        if (isOutlookDesktop) return 'outlook';

        // Check if Gmail is the default handler (this is a basic check)
        const isGmail = document.cookie.includes('GMAIL_LOGIN') || localStorage.getItem('gmail_login');
        if (isGmail) return 'gmail';

        return 'unknown';
    }


}
class pageTitle {
    title : string;
}
class InfoBox1 {
    icon : string;
    title : string;
    location : string;
}
class InfoBox2 {
    icon : string;
    title : string;
    number : string;
    email : string;
}
class InfoBox3 {
    icon : string;
    title : string;
    text1 : string;
    text2 : string;
}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}
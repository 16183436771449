import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-book-demo-page',
  templateUrl: './book-demo-page.component.html',
  styleUrls: ['./book-demo-page.component.scss']
})
export class BookDemoPageComponent implements OnInit {
  demoForm: FormGroup;
  submitted = false;
  showFormError = false;
  searchText: string = '';
  filteredCountries: string[] = [];
  showCountryList = false;
  siteKey:string;
  
  salutations = [
    'Mr.',
    'Mrs.',
    'Ms.',
    'Dr.',
    'Prof.'
  ];

  countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
    "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
    "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica",
    "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador",
    "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon",
    "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
    "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
    "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea North", "Korea South", "Kosovo",
    "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
    "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
    "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman",
    "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent", "Samoa", "San Marino", "Sao Tome", "Saudi Arabia",
    "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan",
    "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
    "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela",
    "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];

  constructor(private fb: FormBuilder) {
    this.filteredCountries = this.countries;
    this.siteKey='6LdzXr8qAAAAAHRFXH8YXhR-zSf03SkaqH8ZpjNq';
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  filterCountries(event: any): void {
    const searchValue = event.target.value.toLowerCase();
    this.filteredCountries = this.countries.filter(country => 
      country.toLowerCase().includes(searchValue)
    );
    this.showCountryList = true;
  }



   selectCountry(country: string) {
    this.demoForm.get('country')?.setValue(country);
    this.showCountryList = false;
  }

 

  private initializeForm(): void {
    this.demoForm = this.fb.group({
      salutation: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      companyName: ['', [Validators.required, Validators.minLength(2)]],
      emailAddress: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]],
      phoneNumber: ['', [
        Validators.required,
        Validators.pattern('^[+]?[(]?[0-9]{3}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{4}$')
      ]],
      city: ['', [Validators.required, Validators.minLength(2)]],
      zipCode: ['', [
        Validators.required,
        Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')
      ]],
      country: ['', Validators.required],
      message: ['', Validators.maxLength(500)]
    });
  }

  // Getter methods for easy access in template
  get f() { return this.demoForm.controls; }

  onSubmit(): void {
    this.submitted = true;
    this.showFormError = false;

    if (this.demoForm.invalid) {
      this.showFormError = true;
      Object.keys(this.demoForm.controls).forEach(key => {
        const control = this.demoForm.get(key);
        if (control) {
          control.markAsTouched();
        }
      });
      return;
    }

    console.log(this.demoForm.value);
  }

  onReset(): void {
    this.submitted = false;
    this.demoForm.reset();
  }

  isFieldInvalid(fieldName: string): boolean {
    const field = this.demoForm.get(fieldName);
    return field ? (field.invalid && (field.dirty || field.touched || this.submitted)) : false;
  }
}
import { Component } from "@angular/core";

// First, define an interface for the plan structure
interface PricingPlan {
  name: string;
  originalPrice: string;
  price: string;
  savings: string;
  features: string[];
  displayPrice?: string;
  displayOriginalPrice?: string;
  displaySavings?: string;
  currencySymbol?: string;
}

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent {
  isAnnual = false;
  selectedCurrency = 'USD';
  exchangeRate = 83.12; // 1 USD = 83.12 INR (approximate rate)
  
  plans: PricingPlan[] = [
    {
      name: 'Basic',
      originalPrice: 'xx',
      price: 'XX',
      savings: 'xx',
      displayPrice: 'xx',
      displayOriginalPrice: 'xx',
      displaySavings: 'xx',
      currencySymbol: 'US$',
      features: [
        'Track income & expenses',
        'Send unlimited custom invoices & quotes',
        'Connect your bank',
        'Track GST & e-lodge†',
        'Track & organize receipts',
        'Progress invoicing',
        'For your own or a single small business'
      ]
    },
    {
      name: 'Intermediate',
      originalPrice: 'xx',
      price: 'x.x',
      savings: 'x',
      displayPrice: 'x.x',
      displayOriginalPrice: 'x',
      displaySavings: 'x',
      currencySymbol: 'US$',
      features: [
        'Track income & expenses',
        'Send unlimited custom invoices & quotes',
        'Connect your bank',
        'Track GST & e-lodge†',
        'Track & organize receipts',
        'Progress invoicing',
        'Manage bills & payments',
        'Multi-currency',
        'For your own & plus your accountants'
      ]
    },
    {
      name: 'Advanced',
      originalPrice: 'x',
      price: 'x.x',
      savings: 'x',
      displayPrice: 'x.x',
      displayOriginalPrice: 'x',
      displaySavings: 'x',
      currencySymbol: 'US$',
      features: [
        'Track income & expenses',
        'Send unlimited custom invoices & quotes',
        'Connect your bank',
        'Track GST & e-lodge†',
        'Track & organize receipts',
        'Progress invoicing',
        'Manage bills & payments',
        'Track employee time',
        'Multi-currency',
        'Track inventory',
        'Track project profitability',
        'Manage budgets',
        'For your own & plus your accountants'
      ]
    }
  ];

  ngOnInit() {
    this.initializeCurrencyDisplay();
  }

  initializeCurrencyDisplay() {
    this.updateCurrency(this.selectedCurrency);
  }

  updateCurrency(currency: string) {
    this.selectedCurrency = currency;
    
    this.plans = this.plans.map(plan => {
      const currentPrice = plan.price; // Use the current price, not the display price
      if (currency === 'INR') {
        return {
          ...plan,
          displayPrice: (parseFloat(currentPrice) * this.exchangeRate).toFixed(2),
          displayOriginalPrice: (parseFloat(plan.originalPrice) * this.exchangeRate).toFixed(2),
          displaySavings: (parseFloat(plan.savings) * this.exchangeRate).toFixed(2),
          currencySymbol: '₹'
        };
      } else {
        return {
          ...plan,
          displayPrice: currentPrice,
          displayOriginalPrice: plan.originalPrice,
          displaySavings: plan.savings,
          currencySymbol: 'US$'
        };
      }
    });
  }

  updatePrices() {
    this.plans = this.plans.map(plan => {
      const newPlan = { ...plan };
      if (this.isAnnual) {
        newPlan.price = (parseFloat(plan.originalPrice) * 0.8).toFixed(2);
      } else {
        newPlan.price = (parseFloat(plan.originalPrice) / 2).toFixed(2);
      }
      return newPlan;
    });
    
    // After updating base prices, update the display prices with current currency
    this.updateCurrency(this.selectedCurrency);
  }
}
import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-tryitnow',
  templateUrl: './tryitnow.component.html',
  styleUrls: ['./tryitnow.component.scss']
})
export class TryitnowComponent {
  @ViewChild('trialSection') trialSection!: ElementRef;
  currentStep = 1;
  totalSteps = 2;
  registrationForm: FormGroup;
  filteredCountries: string[] = [];
  showCountryList = false;
  companies = [
    { name: 'FactoMES', image: 'assets/img/OptebizImage/facto MESLogo.png' },
    { name: 'FactoIQ', image: 'assets/img/OptebizImage/facto IQlogo.png' },
    { name: 'FactoCloudConnect', image: 'assets/img/OptebizImage/Facto Cloud Connect.png' },
    { name: 'FactoPredict', image: 'assets/img/OptebizImage/factopredictive 2.png' },
    { name: 'FactoOEE', image: 'assets/img/OptebizImage/FactoOEE 1.png' },
    { name: 'FactoLake', image: 'assets/img/OptebizImage/factoLake 2.png' },
    { name: 'FactoEye', image: 'assets/img/OptebizImage/FatocEye 2.png' },
    { name: 'FactoQual', image: 'assets/img/OptebizImage/factoQual 1.png' },
    { name: 'FactoMPCP', image: 'assets/img/OptebizImage/factoMPCP.png' },
    { name: 'FactoSPC', image: 'assets/img/OptebizImage/facto SPC.png' }
  ];

  // Sample countries array -
  countries: string[] = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
    "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
    "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica",
    "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador",
    "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon",
    "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
    "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
    "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea North", "Korea South", "Kosovo",
    "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
    "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
    "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman",
    "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent", "Samoa", "San Marino", "Sao Tome", "Saudi Arabia",
    "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan",
    "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
    "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela",
    "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];

  constructor(private fb: FormBuilder) {
    this.registrationForm = this.fb.group({
      step1: this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', Validators.required],
        country: ['', Validators.required]
      }),
      step2: this.fb.group({
        machineCount: ['', Validators.required],
        hearAboutUs: ['', Validators.required]
      })
    });
  }

  filterCountries(event: any) {
    const searchText = event.target.value.toLowerCase();
    this.filteredCountries = this.countries.filter(country => 
      country.toLowerCase().includes(searchText)
    );
    this.showCountryList = true;
  }

  selectCountry(country: string) {
    this.registrationForm.get('step1.country')?.setValue(country);
    this.showCountryList = false;
  }

  onContinue() {
    const step1Form = this.registrationForm.get('step1');
    if (step1Form?.valid) {
      this.currentStep++;
    } else {
      this.markFormGroupTouched(step1Form as FormGroup);
    }
  }

  onBack() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onSubmit() {
    const step2Form = this.registrationForm.get('step2');
    if (step2Form?.valid) {
      console.log('Form submitted:', this.registrationForm.value);
      // Handle form submission
    } else {
      this.markFormGroupTouched(step2Form as FormGroup);
    }
  }

  getErrorMessage(controlName: string, step: string = 'step1'): string {
    const control = this.registrationForm.get(`${step}.${controlName}`);
    if (control?.touched && control?.errors) {
      if (control.errors['required']) {
        return `${controlName.charAt(0).toUpperCase() + controlName.slice(1)} is required`;
      }
      if (control.errors['email']) {
        return 'Please enter a valid email address';
      }
    }
    return '';
  }

  scrollToTrial() {
    const element = this.trialSection.nativeElement;
    const elementPosition = element.offsetTop;
    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate, keyframes } from '@angular/animations';

interface ImageConfig {
  src: string;
  animationIn: any[];
  animationOut: any[];
}

interface Slide {
  id: string;
  stepCounter: string;
  title: string;
  description: string;
  mediaSource: string;
  mediaType: 'image' | 'video';
}

@Component({
  selector: 'app-landing-page-new',
  templateUrl: './landing-page-new.component.html',
  styleUrls: ['./landing-page-new.component.scss'],
  animations: [
    trigger('uniqueImageTransition', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.7)' }),
        animate('1500ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'scale(1)' })
        )
      ]),
      transition(':leave', [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'scale(1.2)' })
        )
      ])
    ])
  ]
})
export class LandingPageNewComponent implements OnInit {
  images: ImageConfig[] = [
    {
      src: 'assets/img/OptebizImage/LandingImg.jpg',
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
    {
      src: 'assets/img/OptebizImage/LandingImg2.jpg',
      animationIn: [
        style({ opacity: 0, transform: 'translateY(50px) scale(0.8)' }),
        animate('2000ms ease-out', 
          style({ opacity: 1, transform: 'translateY(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(100%) rotate(-20deg)' })
        )
      ]
    },
    {
      src: 'assets/img/OptebizImage/Landingimg3.jpg',
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateY(-90deg) scale(0.7)' }),
        animate('2000ms cubic-bezier(0.45, 0, 0.55, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateY(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'scale(1.2) rotate(20deg)' })
        )
      ]
    }
  ];

  slides: Slide[] = [
    {
      id: 'slide-1',
      stepCounter: '01',
      title: 'Real-Time Visibility and <strong>Control</strong>',
      description: 'Achieve end-to-end transparency and centralized monitoring of your production processes, enabling data-driven decision making.',
      mediaSource: 'assets/img/OptebizImage/animation_1new.mp4',
      mediaType: 'video'
    },
    {
      id: 'slide-2',
      stepCounter: '02',
      title: 'Predictive Maintenance and <strong>Optimization</strong>',
      description: 'Leverage AI-powered analytics to forecast equipment failures, optimize asset performance, and maximize Overall Equipment Effectiveness (OEE).',
      mediaSource: 'assets/img/OptebizImage/animation1.mp4',
      mediaType: 'video'
    },
    {
      id: 'slide-3',
      stepCounter: '03',
      title: 'Intelligent Insights for Informed <strong> Decision Making</strong>',
      description: 'Unlock hidden capacity, identify bottlenecks, and drive continuous improvement with comparative analytics and performance benchmarking.',
      mediaSource: 'assets/img/OptebizImage/animation_3.mp4',
      mediaType: 'video'
    }
  ];
  
  currentImageIndex = 0;
  currentSlideIndex = 0;
  
  ngOnInit() {
    this.startImageCycle();
  }
  
  nextSlide() {
    if (this.currentSlideIndex < this.slides.length - 1) {
      this.currentSlideIndex++;
    }
  }
  prevSlide() {
    if (this.currentSlideIndex > 0) {
      this.currentSlideIndex--;
    }
  }
  getProgressWidth(index: number): number {
    return index === this.currentSlideIndex ? 100 : 0;
  }

  startImageCycle() {
    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    }, 3500);
  }

  nextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }

  prevImage() {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.images.length) % this.images.length;
  }

}
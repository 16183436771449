// homeone-banner.component.ts
import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

interface BannerContent {
    title: string;
    paragraphText: string;
    paragraphText1: string;
    defaultBtnIcon: string;
    defaultBtnText: string;
    defaultBtnLink: string;
    videoBtnIcon: string;
    videoBtnText: string;
    videoBtnLink: string;
    featuredServices: {
        icon?: string;
        title?: string;
        paragraphText?: string;
        linkText?: string;
        linkIcon?: string;
        link?: string;
    }[];
    contentSections?: ContentSection[];
}


interface ContentSection {
    imageSrc: string;
    imageAlt: string;
    imageTitle: string;
    contentHeading: string;
    contentDetail: string;
    buttonLink: string;
    buttonText: string;
    alignment: 'left' | 'right';
  }

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss'],
    animations: [
        trigger('slideAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0 }),
                animate('800ms ease-in-out', style({ transform: 'translateX(0)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate('800ms ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
            ])
        ])
    ]
})
export class HomeoneBannerComponent implements OnInit {
    isMainBannerVisible: boolean = true; // Initially show main banner
    currentIndex: number = 0;
    animating: boolean = false;
    slideCount: number = 0;  // Track the number of slides transitioned

    mainBannerContent: BannerContent[] = [


        {
            title: 'Powering Innovation with Ignition',
            paragraphText: "Driving Innovation with Inductive Automation",
            paragraphText1: 'Integrate cutting-edge IoT sensors and AI-powered analytics to transform your factory operations.',
            defaultBtnIcon: 'flaticon-analytics',
            defaultBtnText: 'Our Solutions',
            defaultBtnLink: 'BookDemo',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'View Demo',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo2',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/Inductive-Automation-Ignition-Designer-Logo.png',
                    title: 'Partnering With Ignition',
                    paragraphText: 'Our collaboration with Inductive Automation brings you state-of-the-art Ignition-based solutions that enhance operational efficiency and drive innovation in your manufacturing processes.',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoIQ'
                },
                {
                    icon: 'assets/img/OptebizImage/Downloads.png',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoMES'
                }
            ]
        },
        

        
        {
            title: 'Data Science Empowering Smarter Manufacturing',
            paragraphText: 'Transform Your Production with Intelligent Solutions',
            paragraphText1: 'Leverage advanced analytics and machine learning to optimize your manufacturing processes.',
            defaultBtnIcon: 'flaticon-analytics',
            defaultBtnText: 'Explore Solutions',
            defaultBtnLink: 'BookDemo',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'Watch Demo',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo4',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/Asset 4@36x.png',
                    title: 'FactoCloudConnect',
                    paragraphText: 'Our Factocloud Connect helps integrate the Real-Time-Data coming from shop floor and machine control system into various cloud environments and Big Data Platforms',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoCloud'
                },
                {
                    icon: 'assets/img/OptebizImage/factopredictive 2.png',
                    title: 'FactoPredict',
                    paragraphText: 'FactoPredict AI analyzes manufacturing data to forecast issues, optimize production, and improve resource planning - helping you reduce downtime and deliver better quality products',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoCloud'
                },
            ]
        },
        {
            title: 'Harnessing AI for Smarter Manufacturing Solutions',
            paragraphText: 'Advanced Manufacturing Intelligence at Your Fingertips',
            paragraphText1: 'Drive operational excellence with AI-powered insights and predictive analytics.',
            defaultBtnIcon: 'flaticon-innovation',
            defaultBtnText: 'Get Started',
            defaultBtnLink: 'BookDemo',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'See it in Action',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo5',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/FactoOEE 1.png',
                    title: 'FactoOEE',
                    paragraphText: 'FactoOEE provides manufacturers insights into production processes, helping pinpoint inefficiencies and areas for improvement. Leveraging these insights, manufacturers can reduce waste, enhance productivity, and optimize operational performance.',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoCloud'
                },
                {
                    icon: 'assets/img/OptebizImage/factoLake 2.png',
                    title: 'FactoLake',
                    paragraphText: 'FactoLake tracks manufacturing KPIs and supply chain metrics to identify inefficiencies, optimize resources, and streamline operations - helping you stay competitive in a dynamic market.',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoCloud'
                },
            ]
        },
        {
            title: 'AI and Data Science Shaping the Future of Manufacturing',
            paragraphText: 'Next-Generation Manufacturing Solutions',
            paragraphText1: 'Unlock the power of data-driven decision making in your manufacturing processes.',
            defaultBtnIcon: 'flaticon-structure',
            defaultBtnText: 'Discover More',
            defaultBtnLink: 'BookDemo',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'Watch Overview',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo6',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/FatocEye 2.png',
                    title: 'FactoEye',
                    paragraphText: 'Automated vision-based SOP compliance monitoring that provides real-time feedback to operators and quality insights to managers - empowering better supervision and reporting.',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoCloud'
                },
                {
                    icon: 'assets/img/OptebizImage/factoQual 1.png',
                    title: 'FactoQual',
                    paragraphText: 'FactoQual combines IoT and real-time monitoring to detect defects early, streamline quality control, and enable predictive maintenance - helping you maximize quality and uptime.',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoCloud'
                },
            ]
        },
        {
            title: 'Transforming Manufacturing with Smart AI Insights',
            paragraphText: 'Intelligent Manufacturing for the Digital Age',
            paragraphText1: 'Enhance your manufacturing capabilities with smart, data-driven solutions.',
            defaultBtnIcon: 'flaticon-innovation',
            defaultBtnText: 'Learn More',
            defaultBtnLink: 'about-us',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'View Features',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo7',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/factoMPCP.png',
                    title: 'FactoMPCP',
                    paragraphText: 'FactoMPCP monitors and controls production to ensure efficient operations, consistent quality, and regulatory compliance - keeping your manufacturing process on track.',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoCloud'
                },
                {
                    icon: 'assets/img/OptebizImage/FactoMES.svg',
                    title: 'FactoMES',
                    paragraphText: 'The FactoMES suite provides an all-in-one solution for monitoring every shop floor activity from start to finish. It helps businesses optimize operations and automate workflows, from receiving factory orders and scheduling production runs to managing the movement of finished products.',
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoMES'
                }
            ]
        }
    ];

    constructor() { }
    private slideInterval: any;

    ngOnInit(): void {
        this.startAutoSlide();
    }

    ngOnDestroy() {
        if (this.slideInterval) {
          clearInterval(this.slideInterval);  // Clear the interval when the component is destroyed
        }
      }

      startAutoSlide() {
        this.slideInterval = setInterval(() => {
          if (!this.animating) {
            // Perform the slide transition
            this.nextSlide();
            this.slideCount++;
      
            // // Hide the main banner after 2 slides
            // if (this.slideCount >= 2) {
            //   this.isMainBannerVisible = false;  // Hide the main banner
      
            //   // Set a timeout to make the banner visible again after 5 seconds
            //   setTimeout(() => {
            //     this.isMainBannerVisible = true;  // Make the banner visible again
            //   }, 8000); // 5 seconds delay before making the banner visible again
            // }
          }
        }, 8000); // 5000ms = 5 seconds interval between slides
      }
      
    
      // Method to go to the previous slide
      prevSlide() {
        if (this.animating) return;
        this.animating = true;
        this.currentIndex = (this.currentIndex - 1 + this.mainBannerContent.length) % this.mainBannerContent.length;
        setTimeout(() => this.animating = false, 1000);  // Reset animating state after 1 second
      }
    
      // Method to go to the next slide
      nextSlide() {
        if (this.animating) return;
        this.animating = true;
        this.currentIndex = (this.currentIndex + 1) % this.mainBannerContent.length;
        setTimeout(() => this.animating = false, 1000);  // Reset animating state after 1 second
      }
    
      // Method to toggle visibility of the additional content
      toggleBannerVisibility() {
        this.isMainBannerVisible = !this.isMainBannerVisible;
      }
    

    getFirstWord(title: string): string {
        return title.split(' ')[0];
    }

    getRemainingWords(title: string): string {
        return title.split(' ').slice(1).join(' ');
    }
}
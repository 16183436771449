<div id="main" class="site-main- clearfix">
    <svg style="display: none">
        <style>
            .yb-section {
                --OrangeColor: #ff6e42;
                --lightColor: #fff;
                --primary-blue: var(--lightColor);
                --primary-text-color: var(--lightColor);
            }
            .yb-section:not(.dark-bg) {
                --DarkColor: #000041;
                --OrangeColor: #ff6e42;
                --primary-blue: var(--DarkColor);
                --primary-orange: var(--OrangeColor);
                --primary-text-color: #4e5f6d;
            }
            * {
                box-sizing: border-box;
            }
            .page-template-cloud-solutions {
                position: relative;
            }
            .page-template-cloud-solutions:before {
                content: "";
                width: 100%;
                background: url(/wp-content/uploads/2023/03/hero-bg.svg) left
                    center no-repeat;
                background-size: 100%;
                display: block;
                position: absolute;
                top: -80px;
                height: 1116px;
            }
            div#page {
                overflow: hidden;
            }
            .container {
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }
            .gradient-word {
                color: #ff6e42;
                background: linear-gradient(
                    90deg,
                    #ff6e42 14.06%,
                    #d847c0 55.73%,
                    #9867f9 92.71%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-box-decoration-break: clone;
                font-weight: 700;
            }
            .yb-section .short-detail {
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                color: var(--primary-text-color);
                max-width: 935px;
                margin: 0 auto 32px;
            }
            .yb-section:not(.hero-section) {
                margin: 150px 0 200px;
                position: relative;
            }
            .yb-section:last-child {
                margin-bottom: 0;
            }
            .yb-section:not(.hero-section):before {
                content: "";
                width: 1px;
                height: 70px;
                background-image: linear-gradient(0deg, #fff 50%, #000041 50%);
                background-size: 1px 5px;
                position: absolute;
                top: -113px;
                z-index: 4;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            .yb-section:not(.hero-section):after {
                width: 11px;
                height: 11px;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -113px;
                border: 1px solid #000041;
                border-radius: 100%;
                background: #fff;
                z-index: 5;
                transition: top 0.3s 0.5s linear;
            }
            .yb-section.dark-bg:before {
                background-image: linear-gradient(0deg, #fff 50%, #000041 50%);
                background-size: 1px 5px;
                z-index: 1;
                border: 0;
                top: -58px;
                height: 200px;
            }
            .yb-section.dark-bg:after {
                top: 138px;
                background: #090043;
                border: 2px solid rgb(255 255 255/62%);
            }
            .section-heading {
                font-weight: 700;
                font-size: 38px;
                line-height: 48px;
                color: var(--primary-blue);
                margin-bottom: 16px;
            }
            @media (min-width: 576px) {
                .container {
                    max-width: 100%;
                }
            }
            @media (min-width: 768px) {
                .container {
                    max-width: 100%;
                    box-sizing: border-box;
                }
            }
            @media (min-width: 992px) {
                .container {
                    max-width: 1160px;
                    box-sizing: border-box;
                }
            }
            @media (min-width: 1200px) {
                .container {
                    max-width: 1140px;
                }
                #content .container {
                    max-width: 1248px;
                    padding: 60px;
                    gap: 72px;
                }
            }
            @media (max-width: 1279px) {
                #content {
                    padding: 0 20px;
                }
                #content .container {
                    max-width: 1160px;
                    padding: 60px;
                }
            }
            @media (max-width: 1199px) {
                .yb-section:not(.hero-section):not(:last-child) {
                    margin: 50px 0 150px;
                }
            }
            img {
                max-width: 100%;
                height: auto;
            }
            .c-v2-yugabyte-db-section-triangle {
                padding-bottom: 85px;
                width: 100%;
                position: absolute;
                top: -1px;
                right: 0;
                left: 0;
                overflow: hidden;
                z-index: 0;
            }
            .c-v2-yugabyte-db-section-triangle:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background: #fff;
                -webkit-transform: skewY(-3deg);
                -ms-transform: skewY(-3deg);
                transform: skewY(-3deg);
                -webkit-transform-origin: left top;
                -ms-transform-origin: left top;
                transform-origin: left top;
                z-index: 999;
            }
            .header-inner {
                background: 0 0;
                backdrop-filter: none;
            }
            .page-scroll:not(.mobile_nav_open) .header-inner {
                background: rgba(242, 246, 255, 0.6);
                backdrop-filter: blur(50px);
            }
            .hero-section {
                padding-top: 50px;
                padding-bottom: 50px;
                position: relative;
                overflow: hidden;
            }
            .headline.text-center {
                position: relative;
                z-index: 1;
            }
            .hero-section h1 {
                color: var(--primary-blue);
                font-weight: 700;
                font-size: 52px;
                line-height: 62px;
                margin-top: 50px;
                margin-bottom: 24px;
            }
            .hero-section .subheading {
                color: var(--primary-text-color);
                margin: 0 auto 20px;
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
            }
            .hero-section .button-section {
                margin-top: 25px;
                display: flex;
                gap: 23px;
            }
            .hero-section .right-side {
                width: calc(100% - 552px);
                display: flex;
                justify-content: center;
                position: relative;
            }
            .hero-section .right-side&gt;
            img {
                position: absolute;
                width: 664px;
                height: auto;
                margin: 0;
                left: 50px;
                top: 10px;
                max-width: none;
            }
            .subheading a:hover {
                color: #ff6e42;
            }
            .hero-section .left-side {
                width: 552px;
                position: relative;
                z-index: 1;
                min-height: 378px;
            }
            .hero-section .container {
                display: flex;
            }
            .right-side img {
                margin-left: 50px;
                margin-top: 10px;
                width: auto;
            }
            .hero-section .cards {
                display: flex;
                gap: 24px;
                position: relative;
                z-index: 2;
                max-width: 100%;
            }
            .hero-section .cards .card&gt;
            .button-section {
                margin-top: auto;
            }
            .hero-section .cards .card&gt;
            .button-section a {
                background: #ff6e42;
                border-radius: 6px;
                padding: 13.5px 30px;
                font-weight: 500;
                font-size: 14px;
                line-height: 13px;
                text-align: center;
                color: #fff;
                display: inline-block;
                height: auto;
                position: relative;
                z-index: 1;
                overflow: hidden;
            }
            .hero-section .cards .card&gt;
            .button-section a:after {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                transition: all 0.25s ease;
                background: linear-gradient(
                    90deg,
                    rgb(4, 79, 103),
                    rgb(230, 95, 50)
                );
            }
            .hero-section .cards .card&gt;
            .button-section a:hover:after {
                width: 100%;
            }
            .hero-section .cards .card h3 {
                background: linear-gradient(
                    90deg,
                    #ff6e42 14.06%,
                    #d847c0 55.73%,
                    #9867f9 92.71%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                margin-bottom: 16px;
                max-width: 400px;
                font-weight: 700;
                font-size: 38px;
                line-height: 48px;
            }
            .hero-section .cards .card {
                display: flex;
                flex-flow: column;
                width: 360px;
                padding: 40px;
                max-width: 100%;
                background: #fff;
                box-shadow: 0 10px 30px rgba(0, 0, 65, 0.15);
                border-radius: 16px;
            }
            .hero-section .cards .card .short-detail {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 40px;
            }
            @media (max-width: 1380px) {
                .hero-section .right-side&gt;
                img {
                    position: absolute;
                    width: 584px;
                    height: auto;
                    margin: 0;
                    left: 0;
                    top: 0;
                    max-width: none;
                }
            }
            @media (max-width: 1199px) {
                .hero-section {
                    overflow: hidden;
                    padding-bottom: 150px;
                }
                .hero-section .right-side&gt;
                img {
                    position: static;
                    width: 100%;
                }
            }
            @media (max-width: 991px) {
                .hero-section .container {
                    flex-flow: column;
                }
                .hero-section h1 {
                    font-size: 39px;
                    line-height: 50px;
                    max-width: 100%;
                }
                .subheading {
                    font-size: 16px;
                }
                .hero-section .right-side&gt;
                img {
                    width: 500px;
                    max-width: 100%;
                }
                .hero-section .right-side {
                    margin-bottom: 40px;
                }
                .hero-section .right-side,
                .hero-section .left-side {
                    width: 100%;
                    min-height: 10px;
                }
                .button-section a {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                div#main {
                    padding-top: inherit !important;
                }
            }
            @media (min-width: 768px) {
                div#main {
                    padding-top: 0 !important;
                }
            }
            @media (max-width: 767px) {
                .hero-section h1 {
                    font-size: 29px;
                    line-height: 40px;
                    max-width: 100%;
                }
                .subheading p {
                    margin: 15px 0;
                }
                .hero-section .subheading {
                    font-size: 16px;
                    line-height: normal;
                    margin-bottom: 40px;
                }
                .hero-section .right-side {
                    height: auto;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 50px;
                }
                .hero-section .right-side&gt;
                img {
                    position: static;
                    max-width: 100%;
                }
                .hero-section .left-side {
                    width: 100%;
                }
                .hero-section .container {
                    flex-flow: column;
                }
                .hero-section .button-section a {
                    min-width: 144px;
                }
                .hero-section .button-section {
                    gap: 10px;
                }
            }
            @media (max-width: 600px) {
                .hero-section .cards {
                    flex-flow: column;
                    align-items: center;
                }
                .hero-section {
                    max-height: inherit;
                }
                .hero-section .container {
                    flex-flow: column;
                }
                .hero-section h1 {
                    margin-top: 50px;
                }
                .hero-section .subheading {
                    margin-bottom: 30px;
                }
                .hero-section .left-side {
                    width: 100%;
                }
                .hero-section .button-section {
                    flex-wrap: wrap;
                }
                body {
                    background-size: auto 500px;
                }
            }
            @media (max-width: 767px) {
                div#main {
                    padding-top: 80px;
                }
            }
            @media (max-width: 480px) {
                body:not(.js-lazyloaded) .hero-section .right-side&gt,
                img {
                    height: 315px;
                }
            }
        </style>
    </svg>
    <div class="hero-section yb-section">
        <div class="headline text-center">
            <div class="container">
                <div class="left-side">
                    <h1>
                        <span class="gradient-word">Facto Toolkit</span> to
                        Modernize Optimize & Scale Your Operations
                    </h1>
                    <div class="subheading">
                        Our team will consult with you to determine the
                        technology and platforms you need to fully integrate
                        automation in your industry – and bring the power of
                        data into your own hands. Our FactoToolkit provides
                        solutions to address specific problems you face in your
                        current operations.
                    </div>
                </div>
                <div class="right-side" style="top: -40px">
                    <img
                        src="assets\img\OptebizImage\factotoolkitLatest.svg"
                        alt="FactoToolkit"
                        title="FactoToolkit"
                        class="loaded come-in"
                        style="top: 18%"
                        class="wow animate__animated animate__fadeInDown"
                    />
                </div>
            </div>
            <div class="container">
                <div class="cards">
                    <div class="card">
                        <h3>Consultation</h3>
                        <div class="short-detail">
                            We analyze your current operations and systems
                            involved and any previous data-related work.
                        </div>
                    </div>
                    <div class="card">
                        <h3>Diagnosis</h3>
                        <div class="short-detail">
                            Find the areas that need help the most – filling
                            gaps present from previous solutions, or assessing a
                            solution from scratch.
                        </div>
                    </div>
                    <div class="card">
                        <h3>Integration</h3>
                        <div class="short-detail">
                            Work with your team to implement our solutions, and
                            guide you through the system – ensuring the product
                            is built around you, for you.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" style="padding-top: 8%">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subtitle }}</span
            >
        </div>
        <div class="row">
            <div
                class="col-lg-4 col-md-6 col-sm-12"
                *ngFor="let ServicesBoxContent of singleServicesBoxItem"
            >
                <div class="single-services-box-item">
                    <div class="icon">
                        <img [src]="ServicesBoxContent.icon" alt="image" />
                    </div>
                    <h3>
                        <a>{{ ServicesBoxContent.title }}</a>
                    </h3>
                    <p
                        style="
                            font-family: Nunito, sans-serif;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #333;
                            max-width: 350px;
                        "
                    >
                        {{ ServicesBoxContent.paragraphText }}
                    </p>
                    <!-- <a
                        routerLink="/{{ ServicesBoxContent.link }}"
                        class="learn-more-btn"
                        ><i class="{{ ServicesBoxContent.linkIcon }}"></i
                        >{{ ServicesBoxContent.linkText }}</a
                    > -->
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="container" style="padding-top: 8%">
                <div
                    class="section-title"
                    *ngFor="let sectionTitleContent of sectionTitle"
                >
                    <span class="sub-title"
                        ><img src="assets/img/star-icon.png" alt="image" /> Our
                        Expertise</span
                    >
                </div>
                <div class="row">
                    <div
                        class="col-lg-6 aos-init aos-animate"
                        data-aos="zoom-in"
                    >
                        <img
                            class="img-fluid mt-5 mt-kg-0"
                            src="assets\img\OptebizImage\home page update.svg"
                            alt="Business logic"
                            style="height: 500px"
                        />
                    </div>
                    <div class="col-lg-6 mb-5">
                        <h2
                            data-aos="fade-left"
                            class="aos-init aos-animate"
                            style="color: black; margin: 0px 0px 32px"
                        >
                            Inductive Automation® : Ignition SCADA
                        </h2>
                        <p
                            class="mb-5 aos-init aos-animate"
                            data-aos="fade-right"
                        >
                            <b
                                style="
                                    font-weight: 400;
                                    font-size: 18px;
                                    line-height: 30px;
                                    color: #4e5f6d;
                                    max-width: 935px;
                                    margin: 0 auto 32px;
                                "
                            ></b>
                            Ignition by Inductive Automation is a communications
                            hub. The systems and solutions we create empowers
                            our clients to:
                        </p>
                        <ul
                            style="
                                color: #ff6e42;
                                font-size: 20px;
                                /* list-style: none; */
                                margin: 0 0 0 -0.8125em;
                                padding-left: 10%;
                                font-weight: 600;
                            "
                        >
                            <li>Connect Data Across their Enterprise.</li>
                            <li>Develop Custom Industrial Applications.</li>
                            <li>
                                Collect, Analyze and Deploy Real-Time Data &
                                Reports.
                            </li>
                            <li>
                                Leverage Flexibility with Cross-Platform & Web
                                Compatibility.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <section class="fact-section grey-bg">
            <div class="container">
                <div class="fact-boxes">
                    <div class="col-lg-3">
                        <div class="fact-box text-center mb-40">
                            <div class="icon"><i class="flaticon-up"></i></div>
                            <h2 class="counter">{{ Operational }}%</h2>
                            <p class="title">
                                Increase in Operational Efficiency.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="fact-box text-center mb-40">
                            <div class="icon">
                                <i class="flaticon-user"></i>
                            </div>
                            <h2 class="counter">+{{ Clients }}</h2>
                            <p class="title">
                                Clients served across the world.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="fact-box text-center mb-40">
                            <div class="icon">
                                <i class="flaticon-money"></i>
                            </div>
                            <h2 class="counter">{{ Savings }}%</h2>
                            <p class="title">Savings for Your Bottom Line.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container-fluid bg-body-tertiary">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <img
                            class="img-fluid mt-5 mt-kg-0"
                            src="assets\img\OptebizImage\technology Factotool.png"
                            alt="Logos of tools"
                            style="height: 445px"
                        />
                    </div>
                    <div class="col-lg-6 mb-5">
                        <h2
                            data-aos="fade-left"
                            class="aos-init aos-animate"
                            style="color: black; margin: 0px 0px 32px"
                        >
                            Our FactoTool Landscape
                        </h2>
                        <p
                            class="mb-5 aos-init aos-animate"
                            data-aos="fade-right"
                        >
                            <b
                                style="
                                    font-weight: 400;
                                    font-size: 18px;
                                    line-height: 30px;
                                    color: #4e5f6d;
                                    max-width: 935px;
                                    margin: 0 auto 32px;
                                "
                                >Our Solutions</b
                            >
                            cover a broad variety of technology, allowing you to
                            integrate and build systems to specifically fit your
                            needs.
                        </p>
                        <p
                            class="mb-5 aos-init aos-animate"
                            data-aos="fade-right"
                        >
                            <b
                                style="
                                    font-weight: 400;
                                    font-size: 18px;
                                    line-height: 30px;
                                    color: #4e5f6d;
                                    max-width: 935px;
                                    margin: 0 auto 32px;
                                "
                                >Integrated, Dynamic Solutions</b
                            >
                            We’re here to engineer a custom solution for you –
                            or if you have existing MES/Data-Tracking, address
                            the gaps present to optimize your operations.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

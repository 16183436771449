<!-- Main Landing Page Section -->
<section
    class="elementor-section elementor-top-section background-logo orange-shape right-shape bg-f1f8fb"
>
    <div class="side-image left">
        <img
            src="assets/img/OptebizImage/Downloads.png"
            data-src="assets/img/OptebizImage/Downloads.png"
            alt="Inductive Automation"
            title="Inductive Automation"
        />
        <span>
            <img
                src="assets/img/OptebizImage/Inductive-Automation-Ignition-Designer-LogoNew.png"
                data-src="assets/img/OptebizImage/inductive-automation-llc-logo2.png"
                alt="Inductive Automation"
                title="Inductive Automation"
            />
        </span>
    </div>
    <div class="side-image right">
        <img
            src="assets/img/OptebizImage/cloud-partner-aws-1024x811_Update1.png"
            data-src="assets/img/OptebizImage/cloud-partner-aws-1024x811_Update1.png"
            alt="AWS"
            title="AWS"
        />
    </div>

    <div class="elementor-container">
        <section class="elementor-section">
            <div class="elementor-container">
                <br />
                <h1>
                    <div class="company-name">
                        <span class="lean">Lean</span><span class="q">Q</span
                        ><span class="ubit">ubit</span>
                    </div>
                    <span class="tagline"
                        >enhances operational value through the strategic
                        implementation of
                        <span class="taglineIgn">Ignition®</span> by Inductive
                        Automation</span
                    >
                </h1>
                <!-- <br />
                <br />
                <br />
                <br /> -->
                <p>
                    <span class="lean">Lean</span><span class="q">Q</span
                    ><span class="ubit">ubit</span>
                    brings over 25+ years of expertise to provide customized MES
                    solutions. As a certified Ignition integrator and an
                    accredited AWS partner, we guarantee seamless, scalable, and
                    secure automation for your business.
                </p>
                <!-- <br />
                <br />
                <br />
                <br /> -->
                <p>
                    Unlock the full potential of your enterprise with our
                    cutting-edge automation solutions.
                </p>
                <!-- <br /> -->
                <p>
                    <a
                        href="javascript:void(0);"
                        class="contact-link"
                        (click)="$event.preventDefault(); openContactPopup()"
                        >Contact us</a
                    >
                    today and take the first step towards a more efficient and
                    innovative future!
                    <span class="email-icon" (click)="openContactPopup()">
                        <i class="fa-regular fa-paper-plane"></i>
                    </span>
                </p>
                <!-- Gold Certification Section -->
                <div class="gold-certification-section">
                    <!-- Add particles container -->
                    <div class="particles">
                        <div class="particle"></div>
                        <div class="particle"></div>
                        <div class="particle"></div>
                    </div>

                    <div class="badge-container">
                        <img
                            class="gold-badge"
                            src="assets/img/OptebizImage/GoldCertified.svg"
                            alt="Ignition Gold Certified Partner"
                        />
                    </div>
                    <div class="certification-content">
                        <p class="certification-quote-main">
                            Empowering manufacturing innovation,
                        </p>
                        <p class="certification-quote-company">
                            <span class="lean">Lean</span
                            ><span class="q">Q</span
                            ><span class="ubit">ubit</span> delivers seamless
                            digitization as a
                            <span class="gold">Gold</span> Certified Ignition
                            Partner.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <div class="factomes-container">
            <div class="factomes-container">
                <div class="ai-powered-container">
                    <div class="content-wrapper">
                        <p class="factomes-description">
                            Our <span class="facto">Facto</span
                            ><span class="mes">M</span>
                            <span class="facto">ES</span>suite of applications
                            delivers a comprehensive platform for overseeing
                            shop floor activities, from order intake to final
                            goods movement, allowing businesses to optimize
                            workflows and drive automation
                        </p>
                        <div class="engine-text-wrapper">
                            <img
                                src="assets/img/OptebizImage/AI icon.png"
                                alt="AI Icon"
                                class="ai-icon left-icon"
                            />
                            <p class="ai-engine-text">
                                <span class="lean">Lean</span
                                ><span class="q">Q</span
                                ><span class="ubit">ubit</span>
                                FactoMES is Powered by AI & Properitory Data
                                Science Engine
                            </p>
                            <img
                                src="assets/img/OptebizImage/data scince icon 1.png"
                                alt="Data Science Icon"
                                class="ai-icon right-icon"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- card left -->
            <div class="cards-left">
                <div
                    class="card card-left"
                    [class.expanded]="expandedCardId === 'card1'"
                    (click)="toggleCard('card1')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/Asset 6@36x.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoIQ</h3>
                    <p class="description">
                        Capture real-time shop floor data in a time-series
                        database and analyze it using AI and statistical methods
                        to optimize processes and operations
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>

                <div
                    class="card card-left"
                    [class.expanded]="expandedCardId === 'card2'"
                    (click)="toggleCard('card2')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/FactoOEE 1.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoOEE</h3>
                    <p class="description">
                        FactoOEE offers insights into production processes,
                        helping identify inefficiencies to reduce waste, boost
                        productivity, and optimize operations
                    </p>

                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
                <div
                    class="card card-left"
                    [class.expanded]="expandedCardId === 'card3'"
                    (click)="toggleCard('card3')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/factoMPCP.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoMPCP</h3>
                    <p class="description">
                        FactoMPCP monitors and controls production to ensure
                        efficiency, consistent quality, and regulatory
                        compliance
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
                <div
                    class="card card-left"
                    [class.expanded]="expandedCardId === 'card4'"
                    (click)="toggleCard('card4')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/factoMPCP.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoSPC</h3>
                    <p class="description">
                        FactoSPC monitors processes using statistical techniques
                        to prevent defects, reduce waste, and ensure consistent
                        quality for better decision-making
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
                <div
                    class="card card-left"
                    [class.expanded]="expandedCardId === 'card5'"
                    (click)="toggleCard('card5')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/factoMPCP.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoPlan</h3>
                    <p class="description">
                        LeanQubit FactoPlan optimizes resources, improves
                        efficiency, and ensures timely, budget-friendly project
                        delivery with clear insights for better decision-making
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
            </div>
            <!-- card right -->
            <div class="cards-right">
                <div
                    class="card card-right"
                    [class.expanded]="expandedCardId === 'card6'"
                    (click)="toggleCard('card6')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/Asset 4@36x.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoCloudConnect</h3>
                    <p class="description">
                        FactoCloud Connect integrates real-time shop floor and
                        machine data into cloud environments and Big Data
                        platforms for seamless data management
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
                <div
                    class="card card-right"
                    [class.expanded]="expandedCardId === 'card7'"
                    (click)="toggleCard('card7')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/FatocEye 2.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoEye</h3>
                    <p class="description">
                        Vision-based SOP compliance monitoring with real-time
                        operator feedback and quality insights for better
                        supervision and reporting
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>

                <div
                    class="card card-right"
                    [class.expanded]="expandedCardId === 'card8'"
                    (click)="toggleCard('card8')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/factoQual 1.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoQual</h3>
                    <p class="description">
                        FactoQual is an IoT-powered quality control system for
                        real-time monitoring, early defect detection, and
                        improved product quality
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>

                <div
                    class="card card-right"
                    [class.expanded]="expandedCardId === 'card9'"
                    (click)="toggleCard('card9')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/factoLake 2.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoLake</h3>
                    <p class="description">
                        FactoLake tracks KPIs, identifies inefficiencies, and
                        optimizes resources to streamline operations, reduce
                        waste, and improve supply chain visibility
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>

                <div
                    class="card card-right"
                    [class.expanded]="expandedCardId === 'card10'"
                    (click)="toggleCard('card10')"
                >
                    <div class="icon">
                        <img
                            src="assets/img/OptebizImage/factopredictive 2.png"
                            alt="Icon"
                        />
                    </div>
                    <h3>FactoPredict</h3>
                    <p class="description">
                        FactoPredict AI forecasts issues, optimizes production,
                        and enhances resource planning to reduce downtime and
                        improve product quality
                    </p>
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>

        <section class="contact-section">
            <div class="contact-container">
                <div class="contact-left">
                    <h2>Contacts</h2>
                    <h3 class="contact-subtitle">
                        Want to get in touch with our team?
                    </h3>
                    <p class="contact-description">
                        Have a question about our products, your order, or just
                        want to say hi? Fill out the form and we'll be in touch
                        as soon as possible.
                    </p>
                </div>
                <div class="contact-right">
                    <div class="contact-info">
                        <div class="info-group">
                            <h4 class="info-label">Call Us</h4>
                            <p class="phone-number">P: +1 (419) 476-4520</p>
                            <p class="business-hours">
                                If you have any questions, comments or ideas we
                                can be reached by phone, fax or mail. Weekdays
                                between 8:30-19.00 & weekends 10.00-19.00
                            </p>
                        </div>
                        <div class="info-group">
                            <h4 class="info-label">Email</h4>
                            <p class="email">PareshDalwalla@leanqubit.ai</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- Contact Modal -->
    <div
        class="modal-overlay"
        *ngIf="isModalOpen"
        (click)="closeModalOnOverlay($event)"
    >
        <div class="modal-content">
            <button class="close-button" (click)="closeModal()">&times;</button>
            <h2 class="modal-title">Contact Us</h2>
            <p class="modal-description">
                Fill out the form below and we'll get back to you soon!
            </p>

            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="name">Name *</label>
                    <input
                        id="name"
                        type="text"
                        formControlName="name"
                        [class.error]="
                            contactForm.get('name')?.invalid &&
                            contactForm.get('name')?.touched
                        "
                    />
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('name')?.invalid &&
                            contactForm.get('name')?.touched
                        "
                    >
                        Name is required
                    </div>
                </div>

                <div class="form-group">
                    <label for="email">Email *</label>
                    <input
                        id="email"
                        type="email"
                        formControlName="email"
                        [class.error]="
                            contactForm.get('email')?.invalid &&
                            contactForm.get('email')?.touched
                        "
                    />
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('email')?.invalid &&
                            contactForm.get('email')?.touched
                        "
                    >
                        Please enter a valid email
                    </div>
                </div>

                <div class="form-group">
                    <label for="mobile">Mobile Number</label>
                    <input id="mobile" type="text" formControlName="mobile" />
                </div>

                <div class="form-group">
                    <label for="company">Company/Individual *</label>
                    <input
                        id="company"
                        type="text"
                        formControlName="company"
                        [class.error]="
                            contactForm.get('company')?.invalid &&
                            contactForm.get('company')?.touched
                        "
                    />
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('company')?.invalid &&
                            contactForm.get('company')?.touched
                        "
                    >
                        This field is required
                    </div>
                </div>

                <div class="form-group">
                    <label for="message">Message *</label>
                    <textarea
                        id="message"
                        formControlName="message"
                        rows="4"
                        [class.error]="
                            contactForm.get('message')?.invalid &&
                            contactForm.get('message')?.touched
                        "
                    ></textarea>
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('message')?.invalid &&
                            contactForm.get('message')?.touched
                        "
                    >
                        Message is required
                    </div>
                </div>

                <button
                    type="submit"
                    [disabled]="contactForm.invalid || isSubmitting"
                >
                    {{ isSubmitting ? "Sending..." : "Send Message" }}
                </button>
            </form>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

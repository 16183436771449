<div class="services-container">
    <div class="service-level-tabs">
        <button [routerLink]="'/Basic'" class="tab-button">Basic</button>
        <button [routerLink]="'/interimediate'" class="tab-button">
            Intermediate
        </button>
        <button [routerLink]="'/Advanced'" class="tab-button">Advanced</button>
        <button [routerLink]="'/Add-on-service'" class="tab-button active">
            Add-On-Service
        </button>
    </div>

    <header class="services-header">
        <h1>Add-on Services</h1>
        <p class="subtitle">
            Enhanced support and solutions for growing enterprises
        </p>

        <!-- Key Information Section -->
        <div class="key-info">
            <div class="info-group">
                <h4>Comprehensive Support Solutions</h4>
                <p>
                    Advanced services for established Ignition users and growing
                    enterprises
                </p>
            </div>

            <div class="services-features-container">
                <!-- Services Offered Section -->
                <div class="services-section">
                    <div class="info-group">
                        <h4>Core Features</h4>
                        <ul class="info-list">
                            <li>
                                Remote troubleshooting and system monitoring
                            </li>
                            <li>Dedicated account management</li>
                            <li>Regular system health check-ups</li>
                            <li>Optional on-site assistance</li>
                            <li>App Functional Architecture support</li>
                        </ul>
                    </div>
                </div>

                <!-- Key App Features Section -->
                <div class="features-section">
                    <div class="info-group">
                        <h4>Technical Services</h4>
                        <ul class="info-list">
                            <li>Ignition Licensing Management</li>
                            <li>Hardware Provisioning Guidance</li>
                            <li>Advanced Troubleshooting</li>
                            <li>Custom API Integration Development</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="services-grid">
        <!-- Original service cards -->
        <div *ngFor="let service of services" class="service-card">
            <div class="icon-container">
                <i [class]="service.icon"></i>
            </div>
            <div class="card-content">
                <h3>{{ service.title }}</h3>
                <p>{{ service.description }}</p>
                <ul class="bullet-points">
                    <li *ngFor="let point of service.bulletPoints">
                        <span class="bullet-icon">
                            <i class="fas fa-check-circle"></i>
                        </span>
                        <span class="bullet-text">{{ point }}</span>
                    </li>
                </ul>
            </div>
            <div class="card-overlay"></div>
        </div>
    </div>
</div>

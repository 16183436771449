<div class="services-container">
    <div class="service-level-tabs">
        <button [routerLink]="'/Basic'" class="tab-button active">Basic</button>
        <button [routerLink]="'/interimediate'" class="tab-button">
            Intermediate
        </button>
        <button [routerLink]="'/Advanced'" class="tab-button">Advanced</button>
        <button [routerLink]="'/Add-on-service'" class="tab-button">
            Add-On-Service
        </button>
    </div>
    <header class="services-header">
        <h1>Our Basic Services</h1>
        <p class="subtitle">Small businesses or those new to Ignition</p>

        <!--  Key Information Section -->
        <div class="key-info">
            <div class="info-group">
                <h4>Target Audience</h4>
                <p>Small businesses or those new to Ignition</p>
            </div>

            <div class="services-features-container">
                <!-- Services Offered Section -->
                <div class="services-section">
                    <div class="info-group">
                        <h4>Services Offered</h4>
                        <ul class="info-list">
                            <li>
                                Initial Consultation: 1-2 hour consultation to
                                understand project requirements
                            </li>
                            <li>
                                Quick Troubleshooting: Basic issue resolution
                                via chat or call
                            </li>
                            <li>
                                Ignition Setup Assistance: Help with software
                                installation and licensing
                            </li>
                            <li>
                                Knowledge Base Access: Access to how-to guides,
                                training videos, and FAQs
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Key App Features Section -->
                <div class="features-section">
                    <div class="info-group">
                        <h4>Key App Features</h4>
                        <ul class="info-list">
                            <li>Instant live chat or ticketing system</li>
                            <li>Self-paced learning modules</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="services-grid">
        <div *ngFor="let service of services" class="service-card">
            <div class="icon-container">
                <i [class]="service.icon"></i>
            </div>
            <div class="card-content">
                <h3>{{ service.title }}</h3>
                <p>{{ service.description }}</p>
                <ul *ngIf="service.bulletPoints" class="bullet-points">
                    <li *ngFor="let point of service.bulletPoints">
                        <span class="bullet-icon">
                            <i class="fas fa-check-circle"></i>
                        </span>
                        <span class="bullet-text">{{ point }}</span>
                    </li>
                </ul>
            </div>
            <div class="card-overlay"></div>
        </div>
    </div>
</div>

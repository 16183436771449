import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { CaseStudiesThreeColumnsPageComponent } from './components/pages/case-studies-three-columns-page/case-studies-three-columns-page.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomepageOneComponent } from './components/pages/homepage-one/homepage-one.component';
import { SolutionsComponent } from './components/common/solutions/solutions.component';
import { HomeoneServicesComponent } from './components/pages/homepage-one/homeone-services/homeone-services.component';
import { HomeoneProcessComponent } from './components/pages/homepage-one/homeone-process/homeone-process.component';
import { HomeoneScientistComponent } from './components/pages/homepage-one/homeone-scientist/homeone-scientist.component';
import { HomeoneTestimonialsComponent } from './components/pages/homepage-one/homeone-testimonials/homeone-testimonials.component';
import { HomeonePartnerComponent } from './components/pages/homepage-one/homeone-partner/homeone-partner.component';
import { HomeoneBlogComponent } from './components/pages/homepage-one/homeone-blog/homeone-blog.component';
import { ProjectStartComponent } from './components/common/project-start/project-start.component';
import { HomeoneAboutComponent } from './components/pages/homepage-one/homeone-about/homeone-about.component';
import { HomeoneBannerComponent } from './components/pages/homepage-one/homeone-banner/homeone-banner.component';


import { SubscribeComponent } from './components/common/subscribe/subscribe.component';

import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CaseStudiesDetailsPageComponent } from './components/pages/case-studies-details-page/case-studies-details-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ServicesDetailsFactocloudComponent } from './services-details-factocloud/services-details-factocloud.component';
import { ServicesDetailsFactomesComponent } from './services-details-factomes/services-details-factomes.component';
import { ServiceIAComponent } from './Dashboard/service-ia.component';
import { InductiveDetailsComponent } from './components/pages/homepage-one/inductive-details/inductive-details.component';
import { AWSComponent } from './components/pages/homepage-one/aws/aws.component';
import { TimeSeriesAnalyticsComponent } from './components/pages/time-series-analytics/time-series-analytics.component';
import { MesAnalyticsComponent } from './components/pages/mes-analytics/mes-analytics.component';
import { AlarmAnalyticsComponent } from './components/pages/alarm-analytics/alarm-analytics.component';
import { QualityAnalyticsComponent } from './components/pages/quality-analytics/quality-analytics.component';
import { ProcessControlAnalyticsComponent } from './components/pages/process-control-analytics/process-control-analytics.component';
import { WorkOrderManagementComponent } from './components/pages/work-order-management/work-order-management.component';
import { InventoryManagementComponent } from './components/pages/inventory-management/inventory-management.component';
import { QualityControlComponent } from './components/pages/quality-control/quality-control.component';
import { DowntimeManagementComponent } from './components/pages/downtime-management/downtime-management.component';
import { ReportandAnalyticsComponent } from './components/pages/reportand-analytics/reportand-analytics.component';
import { ProductionMontioringComponent } from './components/pages/production-montioring/production-montioring.component';
import { IntegrationServiceComponent } from './components/pages/integration-service/integration-service.component';
import { ResilientArchitectureComponent } from './components/pages/resilient-architecture/resilient-architecture.component';
import { TriggerBasedComponent } from './components/pages/trigger-based/trigger-based.component';
import { TransactionProgressComponent } from './components/pages/transaction-progress/transaction-progress.component';
import { DynamicTagSelectionComponent } from './components/pages/dynamic-tag-selection/dynamic-tag-selection.component';
import { ProcessMonitoringComponent } from './components/pages/process-monitoring/process-monitoring.component';
import { Testimonialcase2Component } from './components/pages/testimonialcase2/testimonialcase2.component';
import { Testimonialcase3Component } from './components/pages/testimonialcase3/testimonialcase3.component';
import { Testimonialcase4Component } from './components/pages/testimonialcase4/testimonialcase4.component';
import { FactoToolKitComponent } from './components/facto-tool-kit/facto-tool-kit.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeoneCaseStudiesComponent } from './components/pages/homepage-one/homeone-case-studies/homeone-case-studies.component';
import { DataIntegrationComponent } from './components/pages/data-integration/data-integration.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { TestingComponent } from './testing/testing.component';
import { Testing2Component } from './testing2/testing2.component';
import { Testingfrontpage3Component } from './testingfrontpage3/testingfrontpage3.component';
import { Testingfrontpage4Component } from './testingfrontpage4/testingfrontpage4.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { BasicComponent } from './basic/basic.component';
import { InterimediateComponent } from './interimediate/interimediate.component';
import { AdvanceComponent } from './advance/advance.component';
import { AddOnServiceComponent } from './add-on-service/add-on-service.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { TestingMainbannerComponent } from './testing-mainbanner/testing-mainbanner.component';
import { BookDemoPageComponent } from './components/book-demo-page/book-demo-page.component';
import { HttpClientModule } from '@angular/common/http';
import { TryitnowComponent } from './components/tryitnow/tryitnow.component';
import { LandingPageNewComponent } from './components/landing-page-new/landing-page-new.component';


@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    CaseStudiesThreeColumnsPageComponent,
    FooterComponent,
    PreloaderComponent,
    HomepageOneComponent,
    SolutionsComponent,
    HomeoneServicesComponent,
    HomeoneProcessComponent,
    HomeoneScientistComponent,
    HomeoneTestimonialsComponent,
    HomeonePartnerComponent,
    HomeoneBlogComponent,
    ProjectStartComponent,
    HomeoneAboutComponent,
    HomeoneBannerComponent,
    SubscribeComponent,
    AboutPageComponent,
    TeamPageComponent,
    ServicesDetailsPageComponent,
    ContactPageComponent,
    CaseStudiesDetailsPageComponent,
    FaqPageComponent,
    ServicesDetailsFactocloudComponent,
    ServicesDetailsFactomesComponent,
    ServiceIAComponent,
    InductiveDetailsComponent,
    AWSComponent,
    TimeSeriesAnalyticsComponent,
    MesAnalyticsComponent,
    AlarmAnalyticsComponent,
    QualityAnalyticsComponent,
    ProcessControlAnalyticsComponent,
    WorkOrderManagementComponent,
    InventoryManagementComponent,
    QualityControlComponent,
    DowntimeManagementComponent,
    ReportandAnalyticsComponent,
    ProductionMontioringComponent,
    IntegrationServiceComponent,
    ResilientArchitectureComponent,
    TriggerBasedComponent,
    TransactionProgressComponent,
    DynamicTagSelectionComponent,
    ProcessMonitoringComponent,
    Testimonialcase2Component,
    Testimonialcase3Component,
    Testimonialcase4Component,
    FactoToolKitComponent,
    NavbarComponent,
    HomeoneCaseStudiesComponent,
    DataIntegrationComponent,
    PrivacyComponent,
    TermsComponent,
    TestingComponent,
    Testing2Component,
    Testingfrontpage3Component,
    Testingfrontpage4Component,
    LandingpageComponent,
    BasicComponent,
    InterimediateComponent,
    AdvanceComponent,
    AddOnServiceComponent,
    PricingPageComponent,
    TestingMainbannerComponent,
    BookDemoPageComponent,
    TryitnowComponent,
    LandingPageNewComponent
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      closeButton: true
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
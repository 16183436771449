<section
    class="feature-one"
    style="background-color: #f3f2ef; margin: 100px auto"
>
    <div class="pricing-container">
        <h1 class="title">Find a plan that's right for you</h1>

        <div class="ratings">
            <svg
                width="116px"
                height="20px"
                viewBox="0 0 116 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <defs>
                    <path
                        d="M19.3092607,6.59130084 L13.5618376,5.75300699 L10.9917369,0.518485257 C10.6508052,-0.172828419 9.52310791,-0.172828419 9.18217618,0.518485257 L6.61207542,5.75300699 L0.863643687,6.59130084 C0.483373681,6.64705195 0.167658796,6.91465724 0.0496439662,7.28160086 C-0.069379537,7.64854449 0.0294704911,8.05096608 0.304838426,8.32059869 L4.46460899,12.3954975 L3.48216076,18.1479976 C3.41760564,18.5281188 3.57294139,18.9133082 3.88361291,19.1393536 C4.1952931,19.3664127 4.60683199,19.3968223 4.94574638,19.2163915 L10.0869565,16.500806 L15.2281667,19.2163915 C15.375433,19.294443 15.5368208,19.332962 15.6972,19.332962 C15.9059954,19.332962 16.1147909,19.268088 16.2903001,19.1393536 C16.6009716,18.9133082 16.7563074,18.5281188 16.6917523,18.1479976 L15.7103127,12.3954975 L19.8690746,8.32059869 C20.1444426,8.05096608 20.2432926,7.64854449 20.1242691,7.28160086 C20.0052456,6.91465724 19.6895307,6.64705195 19.3092607,6.59130084 Z"
                        id="path-1"
                    ></path>
                    <path
                        d="M43.6860723,6.59130084 L37.9386492,5.75300699 L35.3685485,0.518485257 C35.0276168,-0.172828419 33.8999195,-0.172828419 33.5589878,0.518485257 L30.988887,5.75300699 L25.2404553,6.59130084 C24.8601853,6.64705195 24.5444704,6.91465724 24.4264556,7.28160086 C24.3074321,7.64854449 24.4062821,8.05096608 24.68165,8.32059869 L28.8414206,12.3954975 L27.8589724,18.1479976 C27.7944172,18.5281188 27.949753,18.9133082 28.2604245,19.1393536 C28.5721047,19.3664127 28.9836436,19.3968223 29.322558,19.2163915 L34.4637681,16.500806 L39.6049783,19.2163915 C39.7522446,19.294443 39.9136324,19.332962 40.0740116,19.332962 C40.282807,19.332962 40.4916025,19.268088 40.6671117,19.1393536 C40.9777832,18.9133082 41.133119,18.5281188 41.0685639,18.1479976 L40.0871243,12.3954975 L44.2458862,8.32059869 C44.5212541,8.05096608 44.6201042,7.64854449 44.5010807,7.28160086 C44.3820572,6.91465724 44.0663423,6.64705195 43.6860723,6.59130084 Z"
                        id="path-3"
                    ></path>
                    <path
                        d="M67.2223042,6.59130084 L61.4748811,5.75300699 L58.9047804,0.518485257 C58.5638486,-0.172828419 57.4361514,-0.172828419 57.0952197,0.518485257 L54.5251189,5.75300699 L48.7766872,6.59130084 C48.3964172,6.64705195 48.0807023,6.91465724 47.9626874,7.28160086 C47.8436639,7.64854449 47.942514,8.05096608 48.2178819,8.32059869 L52.3776525,12.3954975 L51.3952042,18.1479976 C51.3306491,18.5281188 51.4859849,18.9133082 51.7966564,19.1393536 C52.1083366,19.3664127 52.5198755,19.3968223 52.8587899,19.2163915 L58,16.500806 L63.1412101,19.2163915 C63.2884765,19.294443 63.4498643,19.332962 63.6102434,19.332962 C63.8190389,19.332962 64.0278344,19.268088 64.2033436,19.1393536 C64.5140151,18.9133082 64.6693509,18.5281188 64.6047958,18.1479976 L63.6233562,12.3954975 L67.7821181,8.32059869 C68.057486,8.05096608 68.1563361,7.64854449 68.0373126,7.28160086 C67.9182891,6.91465724 67.6025742,6.64705195 67.2223042,6.59130084 Z"
                        id="path-5"
                    ></path>
                    <path
                        d="M90.758536,6.59130084 L85.011113,5.75300699 L82.4410123,0.518485257 C82.1000805,-0.172828419 80.9723833,-0.172828419 80.6314515,0.518485257 L78.0613508,5.75300699 L72.312919,6.59130084 C71.932649,6.64705195 71.6169342,6.91465724 71.4989193,7.28160086 C71.3798958,7.64854449 71.4787459,8.05096608 71.7541138,8.32059869 L75.9138844,12.3954975 L74.9314361,18.1479976 C74.866881,18.5281188 75.0222168,18.9133082 75.3328883,19.1393536 C75.6445685,19.3664127 76.0561074,19.3968223 76.3950217,19.2163915 L81.5362319,16.500806 L86.677442,19.2163915 C86.8247084,19.294443 86.9860962,19.332962 87.1464753,19.332962 C87.3552708,19.332962 87.5640663,19.268088 87.7395755,19.1393536 C88.050247,18.9133082 88.2055828,18.5281188 88.1410276,18.1479976 L87.1595881,12.3954975 L91.31835,8.32059869 C91.5937179,8.05096608 91.6925679,7.64854449 91.5735444,7.28160086 C91.4545209,6.91465724 91.1388061,6.64705195 90.758536,6.59130084 Z"
                        id="path-7"
                    ></path>
                    <path
                        d="M115.135348,6.59130084 L109.387925,5.75300699 L106.817824,0.518485257 C106.476892,-0.172828419 105.349195,-0.172828419 105.008263,0.518485257 L102.438162,5.75300699 L96.6897306,6.59130084 C96.3094606,6.64705195 95.9937458,6.91465724 95.8757309,7.28160086 C95.7567074,7.64854449 95.8555574,8.05096608 96.1309254,8.32059869 L100.290696,12.3954975 L99.3082477,18.1479976 C99.2436926,18.5281188 99.3990284,18.9133082 99.7096999,19.1393536 C100.02138,19.3664127 100.432919,19.3968223 100.771833,19.2163915 L105.913043,16.500806 L111.054254,19.2163915 C111.20152,19.294443 111.362908,19.332962 111.523287,19.332962 C111.732082,19.332962 111.940878,19.268088 112.116387,19.1393536 C112.427059,18.9133082 112.582394,18.5281188 112.517839,18.1479976 L111.5364,12.3954975 L115.695162,8.32059869 C115.97053,8.05096608 116.06938,7.64854449 115.950356,7.28160086 C115.831333,6.91465724 115.515618,6.64705195 115.135348,6.59130084 Z"
                        id="path-9"
                    ></path>
                </defs>
                <g
                    id="Symbols"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                >
                    <g
                        id="review-bar-float"
                        transform="translate(0.000000, -4.000000)"
                    >
                        <g id="stars">
                            <g transform="translate(0.000000, 4.000000)">
                                <mask id="mask-2" fill="white">
                                    <use xlink:href="#path-1"></use>
                                </mask>
                                <use
                                    id="path-1"
                                    fill="#1d9395"
                                    xlink:href="#path-1"
                                ></use>
                                <mask id="mask-4" fill="white">
                                    <use xlink:href="#path-3"></use>
                                </mask>
                                <use
                                    id="path-1"
                                    fill="#1d9395"
                                    xlink:href="#path-3"
                                ></use>
                                <mask id="mask-6" fill="white">
                                    <use xlink:href="#path-5"></use>
                                </mask>
                                <use
                                    id="path-1"
                                    fill="#1d9395"
                                    xlink:href="#path-5"
                                ></use>
                                <mask id="mask-8" fill="white">
                                    <use xlink:href="#path-7"></use>
                                </mask>
                                <use
                                    id="path-1"
                                    fill="#1d9395"
                                    xlink:href="#path-7"
                                ></use>
                                <mask id="mask-10" fill="white">
                                    <use xlink:href="#path-9"></use>
                                </mask>
                                <path
                                    stroke="#1d9395"
                                    stroke-width="2"
                                    d="M114.990289,7.58072391 L114.991019,7.58083068 L108.722417,6.6665199 L108.490286,6.1937391 L105.920958,0.960788089 C105.946742,1.01307064 105.879345,1.01307064 105.905902,0.959217375 L103.103663,6.66653427 L102.582466,6.74254044 L96.8347892,7.58072391 C96.8319432,7.58114117 96.8290514,7.58359318 96.8269425,7.59014018 C96.8250238,7.59605556 96.8265183,7.60214041 96.8307071,7.60624195 L101.364588,12.0476182 L101.276423,12.5638462 L100.294132,18.3154281 C100.292942,18.322434 100.295576,18.3289475 100.29852,18.3310897 C100.301771,18.3334581 100.302267,18.3334942 100.304783,18.3321608 L105.913043,15.3698794 L106.380094,15.6165753 L111.522549,18.3328195 C111.522723,18.3329118 111.522927,18.332962 111.523287,18.332962 C111.524711,18.332962 111.525236,18.3327928 111.528041,18.3307444 C111.530511,18.3289475 111.533145,18.322434 111.532083,18.3161784 L110.462598,12.0476184 L110.836531,11.6812256 L114.995535,7.60609021 C114.999569,7.60214041 115.001063,7.59605556 114.999144,7.59014018 C114.997269,7.58435697 114.993543,7.581201 114.990289,7.58072391 Z"
                                ></path>
                                <rect
                                    id="Rectangle"
                                    fill="#1d9395"
                                    mask="url(#mask-10)"
                                    x="96"
                                    y="0"
                                    width="10"
                                    height="20"
                                ></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <span class="reviews">6,240+ reviews Capterra</span>
        </div>

        <div class="features">
            <div class="feature">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    fill="none"
                >
                    <circle
                        cx="15.5"
                        cy="15.5"
                        r="15.5"
                        fill="#1d9395"
                    ></circle>
                    <path
                        fill="#fff"
                        d="M15.5 6.75a8.75 8.75 0 1 0 8.75 8.75 8.76 8.76 0 0 0-8.75-8.75Zm-3.493 14.807v-.242a2.047 2.047 0 0 1 2.04-2.045.875.875 0 0 0 .875-.875v-.392a.875.875 0 0 0-.438-.762 2.025 2.025 0 0 1-1.009-1.761v-1.461c.001-.537.213-1.051.59-1.433a2.038 2.038 0 0 1 3.487 1.438v1.461a2.045 2.045 0 0 1-.58 1.432 2.178 2.178 0 0 1-.428.324.874.874 0 0 0-.448.763v.394a.875.875 0 0 0 .875.875 2.045 2.045 0 0 1 2.035 2.051v.222a6.94 6.94 0 0 1-7 .008l.001.003Zm8.588-1.272a3.762 3.762 0 0 0-.942-1.641 3.806 3.806 0 0 0-1.166-.802c.53-.67.818-1.499.818-2.352v-1.462a3.79 3.79 0 0 0-3.785-3.794 3.788 3.788 0 0 0-3.791 3.784v1.46a3.797 3.797 0 0 0 .814 2.356 3.808 3.808 0 0 0-2.135 2.45 7 7 0 1 1 10.189 0h-.002Z"
                    ></path>
                </svg>
                Cancel anytime
            </div>
            <div class="feature">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    fill="none"
                >
                    <circle
                        cx="15.5"
                        cy="15.5"
                        r="15.5"
                        fill="#1d9395"
                    ></circle>
                    <path
                        fill="#fff"
                        d="M22.085 7.755a.88.88 0 0 0-.851-.038l-5.066 2.533h-5.043A2.625 2.625 0 0 0 8.5 12.875v3.5A2.625 2.625 0 0 0 11.125 19h1.157l1.53 3.826a.877.877 0 0 0 1.626-.652L14.168 19h2l5.066 2.533a.874.874 0 0 0 1.266-.783V8.5a.875.875 0 0 0-.415-.745Zm-11.835 8.62v-3.5a.875.875 0 0 1 .875-.875H15.5v5.25h-4.375a.875.875 0 0 1-.875-.875Zm10.5 2.96-3.5-1.75v-5.92l3.5-1.75v9.42Z"
                    ></path>
                </svg>
                Unlimited support
            </div>
            <div class="feature">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="31"
                    fill="none"
                >
                    <circle
                        cx="15.5"
                        cy="15.5"
                        r="15.5"
                        fill="#1d9395"
                    ></circle>
                    <path
                        fill="#fff"
                        d="M14.625 24.25h-7a1.75 1.75 0 0 1-1.75-1.75v-.709A3.805 3.805 0 0 1 8.15 18.31a3.8 3.8 0 0 1-.817-2.354v-1.461A3.81 3.81 0 0 1 8.44 11.82a3.876 3.876 0 0 1 5.364-.006 3.818 3.818 0 0 1 1.11 2.68v1.461a3.79 3.79 0 0 1-.816 2.356 3.802 3.802 0 0 1 2.277 3.48v.709a1.75 1.75 0 0 1-1.75 1.75Zm-3.5-11.795a2.037 2.037 0 0 0-2.039 2.039v1.461a2.023 2.023 0 0 0 1.01 1.76.875.875 0 0 1 .438.76v.394a.875.875 0 0 1-.875.875 2.046 2.046 0 0 0-2.034 2.047v.709h7v-.709a2.046 2.046 0 0 0-2.039-2.047.875.875 0 0 1-.875-.875v-.394a.874.874 0 0 1 .447-.762c.155-.091.3-.2.428-.326.373-.384.58-.898.578-1.432v-1.461a2.035 2.035 0 0 0-2.039-2.039Z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M16.375 17.25a.876.876 0 0 1-.782-1.266l.875-1.75a.875.875 0 0 1 .782-.484h5.539a.587.587 0 0 0 .586-.586V9.086a.587.587 0 0 0-.586-.586H15.21a.587.587 0 0 0-.586.586v.289a.875.875 0 0 1-1.75 0v-.289a2.338 2.338 0 0 1 2.336-2.336h7.578a2.338 2.338 0 0 1 2.336 2.336v4.078a2.338 2.338 0 0 1-2.336 2.336H17.79l-.628 1.266a.875.875 0 0 1-.788.484Z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M16.375 12a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75ZM19 12a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75ZM21.625 12a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Z"
                    ></path>
                </svg>
                Free guided setup
            </div>
        </div>

        <div class="billing-toggle">
            <span [class.active]="!isAnnual">Pay Monthly</span>
            <label class="switch">
                <input
                    type="checkbox"
                    [(ngModel)]="isAnnual"
                    (change)="updatePrices()"
                />
                <span class="slider round"></span>
            </label>
            <span [class.active]="isAnnual">Pay Annually </span>
        </div>

        <!-- HTML Template -->
        <div class="currency-note">
            <div class="content-wrapper">
                <span class="note-text"
                    >Billing will be processed in USD. View prices in your local
                    currency</span
                >
                <div class="currency-selector">
                    <label>
                        <input
                            type="radio"
                            name="currency"
                            [value]="'USD'"
                            [(ngModel)]="selectedCurrency"
                            (change)="updateCurrency('USD')"
                        />
                        <span class="custom-radio"></span>
                        USD
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="currency"
                            [value]="'INR'"
                            [(ngModel)]="selectedCurrency"
                            (change)="updateCurrency('INR')"
                        />
                        <span class="custom-radio"></span>
                        INR
                    </label>
                </div>
            </div>
        </div>

        <div class="pricing-grid">
            <div class="pricing-card" *ngFor="let plan of plans">
                <h2>{{ plan.name }}</h2>
                <div class="price" style="text-align: center">
                    <span class="original-price"
                        >{{ plan.currencySymbol
                        }}{{ plan.displayOriginalPrice }}</span
                    >
                    <div class="current-price">
                        <span class="amount"
                            >{{ plan.currencySymbol
                            }}{{ plan.displayPrice }}</span
                        >
                        <span class="period">/mo</span>
                    </div>
                    <div class="savings">
                        Save {{ plan.currencySymbol
                        }}{{ plan.displaySavings }}/mo for 3 months
                    </div>
                </div>

                <button class="btn-buy">Buy now</button>
                <button class="btn-trial">Start free trial</button>

                <ul class="features-list">
                    <li *ngFor="let feature of plan.features">
                        <span class="check">✓</span> {{ feature }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<div class="main-container">
    <!-- Left Column -->
    <div class="col-lg-4 col-md-12">
        <div class="main-banner-content" [@slideAnimation]="currentIndex">
            <!-- Title Section -->
            <div
                class="c-v2-intro-title c-v2-intro-title-fade-words animation-element fade-in-up in-view"
            >
                <h1>
                    <ng-container *ngIf="mainBannerContent[currentIndex].title">
                        <span class="animate-charcter">{{
                            getFirstWord(mainBannerContent[currentIndex].title)
                        }}</span>
                        {{
                            getRemainingWords(
                                mainBannerContent[currentIndex].title
                            )
                        }}
                    </ng-container>
                </h1>
                <div class="c-v2-intro-title-hidden"></div>
            </div>
            <!-- Description -->
            <p>
                {{ mainBannerContent[currentIndex].paragraphText }}
            </p>
            <!-- Buttons -->
            <div class="btn-box">
                <a
                    [routerLink]="[
                        '/',
                        mainBannerContent[currentIndex].defaultBtnLink
                    ]"
                    class="default-btn wow animate__animated animate__fadeInRight"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                >
                    <i
                        [class]="mainBannerContent[currentIndex].defaultBtnIcon"
                    ></i>
                    {{ mainBannerContent[currentIndex].defaultBtnText }}
                    <span></span>
                </a>
                <a
                    [href]="mainBannerContent[currentIndex].videoBtnLink"
                    class="video-btn wow animate__animated animate__fadeInLeft popup-youtube"
                >
                    <i
                        [class]="mainBannerContent[currentIndex].videoBtnIcon"
                    ></i>
                    {{ mainBannerContent[currentIndex].videoBtnText }}
                </a>
            </div>
        </div>
    </div>

    <!-- Right Column - Flow Diagram -->
    <div class="col-lg-8 col-md-12">
        <div class="flow-diagram">
            <section>
                <!-- Ignition SCADA Container -->
                <div class="scada-container">
                    <div
                        style="
                            background: linear-gradient(
                                135deg,
                                #0288d1 0%,
                                #026ca3 100%
                            );
                            border-radius: 12px;
                            padding: 15px 25px;
                            margin-bottom: 20px;
                            box-shadow: 0 4px 20px rgba(2, 136, 209, 0.2);
                            transition: all 0.3s ease;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            overflow: hidden;
                            text-align: center;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 12px;
                                position: relative;
                                z-index: 1;
                            "
                        >
                            <div
                                style="
                                    background: rgba(255, 255, 255, 0.2);
                                    padding: 8px;
                                    border-radius: 8px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <div
                                    style="
                                        width: 24px;
                                        height: 24px;
                                        animation: pulse 2s infinite;
                                    "
                                >
                                    <svg
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="white"
                                        stroke-width="2"
                                        style="width: 24px; height: 24px"
                                    >
                                        <rect
                                            x="2"
                                            y="3"
                                            width="20"
                                            height="14"
                                            rx="2"
                                            ry="2"
                                        />
                                        <line x1="8" y1="21" x2="16" y2="21" />
                                        <line x1="12" y1="17" x2="12" y2="21" />
                                    </svg>
                                </div>
                            </div>
                            <h2
                                style="
                                    margin: 0;
                                    color: white;
                                    font-size: 18px;
                                    font-weight: 600;
                                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                                "
                            >
                                Ignition SCADA
                            </h2>
                        </div>
                    </div>
                </div>

                <!-- Cards Rows -->
                <!-- Top Row Cards -->
                <div class="cards-row top-cards">
                    <div
                        class="card-container"
                        *ngFor="let card of cards.slice(0, 5); let i = index"
                    >
                        <div
                            [class]="'card ' + card.id"
                            [ngClass]="{ active: currentCardIndex === i }"
                            (click)="onCardClick(i, false)"
                        >
                            <div class="content-wrapper">
                                <div class="shape">
                                    <img
                                        src="assets/img/services/shape4.png"
                                        alt="image"
                                    />
                                </div>
                                <div
                                    class="icon-container"
                                    *ngIf="currentCardIndex === i"
                                >
                                    <img
                                        [src]="card.data.iconUrl"
                                        [alt]="card.data.title"
                                    />
                                </div>
                                <h3>{{ card.data.title }}</h3>
                                <p *ngIf="currentCardIndex === i">
                                    {{ card.data.description }}
                                </p>
                                <a
                                    href="#"
                                    class="learn-more"
                                    *ngIf="currentCardIndex === i"
                                >
                                    Learn More <span class="arrow">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Middle Section with Line and Connectors -->
                <div class="middle-section">
                    <div class="horizontal-line"></div>
                    <div class="connectors">
                        <div
                            class="connector-line"
                            *ngFor="let card of cards; let i = index"
                            [ngClass]="{
                                'top-connector': i < 5,
                                'bottom-connector': i >= 5
                            }"
                            [style.left]="(i % 5) * 20 + 10 + '%'"
                        ></div>
                    </div>
                </div>

                <!-- Bottom Row Cards -->
                <div class="cards-row bottom-cards">
                    <div
                        class="card-container"
                        *ngFor="let card of cards.slice(5, 10); let i = index"
                    >
                        <div
                            [class]="'card ' + card.id"
                            [ngClass]="{ active: currentCardIndex === i + 5 }"
                            (click)="onCardClick(i, true)"
                        >
                            <div class="content-wrapper">
                                <div class="shape">
                                    <img
                                        src="assets/img/services/shape4.png"
                                        alt="image"
                                    />
                                </div>
                                <div
                                    class="icon-container"
                                    *ngIf="currentCardIndex === i + 5"
                                >
                                    <img
                                        [src]="card.data.iconUrl"
                                        [alt]="card.data.title"
                                    />
                                </div>
                                <h3>{{ card.data.title }}</h3>
                                <p *ngIf="currentCardIndex === i + 5">
                                    {{ card.data.description }}
                                </p>
                                <a
                                    href="#"
                                    class="learn-more"
                                    *ngIf="currentCardIndex === i + 5"
                                >
                                    Learn More <span class="arrow">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- AWS Container -->
                <div class="aws-container">
                    <div
                        style="
                            background: linear-gradient(
                                135deg,
                                #232f3e 0%,
                                #37475a 100%
                            );
                            border-radius: 12px;
                            padding: 15px 25px;
                            box-shadow: 0 4px 20px rgba(35, 47, 62, 0.2);
                            transition: all 0.3s ease;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            overflow: hidden;
                            text-align: center;
                        "
                    >
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 12px;
                                position: relative;
                                z-index: 1;
                            "
                        >
                            <div
                                style="
                                    background: rgba(255, 153, 0, 0.2);
                                    padding: 8px;
                                    border-radius: 8px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <div
                                    style="
                                        width: 24px;
                                        height: 24px;
                                        animation: pulse 2s infinite;
                                    "
                                >
                                    <svg
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#ff9900"
                                        stroke-width="2"
                                        style="width: 24px; height: 24px"
                                    >
                                        <path
                                            d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <h2
                                style="
                                    margin: 0;
                                    color: white;
                                    font-size: 18px;
                                    font-weight: 600;
                                    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                                "
                            >
                                Amazon Web Services
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

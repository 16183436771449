<div class="services-container">
    <div class="service-level-tabs">
        <button [routerLink]="'/Basic'" class="tab-button">Basic</button>
        <button [routerLink]="'/interimediate'" class="tab-button">
            Intermediate
        </button>
        <button [routerLink]="'/Advanced'" class="tab-button active">
            Advanced
        </button>
        <button [routerLink]="'/Add-on-service'" class="tab-button">
            Add-On-Service
        </button>
    </div>

    <header class="services-header">
        <h1>Our Advanced Services</h1>
        <p class="subtitle">
            Enterprise-grade solutions for complex industrial automation
            challenges
        </p>
        <!-- Added Key Information Section -->
        <div class="key-info">
            <div class="info-group">
                <h4>Target Audience</h4>
                <p>Enterprises with large-scale implementation needs</p>
            </div>

            <div class="services-features-container">
                <!-- Services Offered Section -->
                <div class="services-section">
                    <div class="info-group">
                        <h4>Services Offered</h4>
                        <ul class="info-list">
                            <li>
                                Turnkey Solutions: Complete Ignition project
                                development and deployment
                            </li>
                            <li>
                                Advanced Training: Ignition Edge, Perspective &
                                advanced scripting
                            </li>
                            <li>
                                24/7 Support: Priority support for
                                mission-critical systems
                            </li>
                            <li>
                                Post-Implementation Support: System monitoring,
                                updates & upgrades
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Key App Features Section -->
                <div class="features-section">
                    <div class="info-group">
                        <h4>Key App Features</h4>
                        <ul class="info-list">
                            <li>
                                Real-time collaboration (video calls, shared
                                dashboards)
                            </li>
                            <li>SLA-based support escalation</li>
                            <li>
                                Custom analytics to measure system performance
                                and ROI
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="services-grid">
        <div *ngFor="let service of services" class="service-card">
            <div class="icon-container">
                <i [class]="service.icon"></i>
            </div>
            <div class="card-content">
                <h3>{{ service.title }}</h3>
                <p>{{ service.description }}</p>

                <ul *ngIf="service.bulletPoints" class="bullet-points">
                    <li *ngFor="let point of service.bulletPoints">
                        <span class="bullet-icon">
                            <i class="fas fa-check-circle"></i>
                        </span>
                        <span class="bullet-text">{{ point }}</span>
                    </li>
                </ul>
            </div>
            <div class="card-overlay"></div>
        </div>
    </div>
</div>

<div class="page-wrapper">
    <div class="green-strap"></div>
    <div class="demo-container">
        <h1 class="demo-title">book a demo</h1>

        <div class="demo-content">
            <div class="info-section">
                <h2>
                    SEE WHY WORLD CLASS MANUFACTURERS HAVE CONNECTED THOUSANDS
                    OF MACHINES TO <span style="color: #1d9395">Lean</span
                    ><span style="color: #da5c30">Q</span
                    ><span style="color: #1d9395">ubit</span> ACROSS GLOBAL
                    FACTORIES.
                </h2>
                <p>
                    You'll learn how easy it is to self-install and drive
                    actionable insights with our intuitive, flexible machine
                    data platform, as well as how to deliver powerful use cases
                    to your team in a matter of minutes that go right to your
                    bottom line including how to:
                </p>

                <div class="features-grid">
                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M19 3H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"
                            />
                            <path d="M7 12h2v5H7zm4-3h2v8h-2zm4-4h2v12h-2z" />
                        </svg>
                        <span>Increase Machine Utilization</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"
                            />
                        </svg>
                        <span>Benchmark Production vs. Industry Standards</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"
                            />
                        </svg>
                        <span>Monitor Downtime Losses Better</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11z"
                            />
                        </svg>
                        <span>Optimize Production Schedule</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 14H6v-2h6v2zm4-4H6v-2h10v2zm0-4H6V7h10v2z"
                            />
                        </svg>
                        <span>Automate Production Data Collection</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
                            />
                        </svg>
                        <span>Improve Operator Efficiency</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"
                            />
                        </svg>
                        <span>Power Real-Time OEE Analytics</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
                            />
                        </svg>
                        <span
                            >Track Real-Time Plant Visibility
                            Retrospectively</span
                        >
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                            />
                        </svg>
                        <span>Justify New Equipment Purchases</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                            />
                        </svg>
                        <span>Eliminate Testing and Quality Problems</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
                            />
                        </svg>
                        <span>Identify Production Bottlenecks</span>
                    </div>

                    <div class="feature-item">
                        <svg
                            width="40"
                            height="40"
                            viewBox="0 0 24 24"
                            fill="#da5c30"
                        >
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm2-4h-4v-2h4v2zm0-4h-4V8h4v4z"
                            />
                        </svg>
                        <span>Drive Automation via Data Integration</span>
                    </div>
                </div>
            </div>

            <div class="form-section">
                <h3>
                    REQUEST A PERSONALIZED DEMO OF
                    <span style="color: #1d9395">Lean</span
                    ><span style="color: #da5c30">Q</span
                    ><span style="color: #1d9395">ubit</span>
                </h3>
                <form
                    [formGroup]="demoForm"
                    (ngSubmit)="onSubmit()"
                    class="form-wrapper"
                >
                    <!-- First Name and Last Name -->
                    <div class="form-row">
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('firstName')"
                        >
                            <input
                                type="text"
                                formControlName="firstName"
                                placeholder="First Name*"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('firstName')"
                            >
                                First name is required
                            </div>
                        </div>
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('lastName')"
                        >
                            <input
                                type="text"
                                formControlName="lastName"
                                placeholder="Last Name*"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('lastName')"
                            >
                                Last name is required
                            </div>
                        </div>
                    </div>

                    <!-- Company/Institution and Email -->
                    <div class="form-row">
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('companyName')"
                        >
                            <input
                                type="text"
                                formControlName="companyName"
                                placeholder="Company/Institution*"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('companyName')"
                            >
                                Company/Institution is required
                            </div>
                        </div>
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('emailAddress')"
                        >
                            <input
                                type="email"
                                formControlName="emailAddress"
                                placeholder="Email Address*"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('emailAddress')"
                            >
                                Please enter a valid email address
                            </div>
                        </div>
                    </div>

                    <!-- Phone and ZIP Code -->
                    <div class="form-row">
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('phoneNumber')"
                        >
                            <input
                                type="tel"
                                formControlName="phoneNumber"
                                placeholder="Phone Number*"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('phoneNumber')"
                            >
                                Please enter a valid phone number
                            </div>
                        </div>
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('zipCode')"
                        >
                            <input
                                type="text"
                                formControlName="zipCode"
                                placeholder="ZIP Code*"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('zipCode')"
                            >
                                Please enter a valid ZIP code
                            </div>
                        </div>
                    </div>

                    <!-- City and Country -->
                    <div class="form-row">
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('city')"
                        >
                            <input
                                type="text"
                                formControlName="city"
                                placeholder="City*"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('city')"
                            >
                                City is required
                            </div>
                        </div>
                        <div class="form-group country-input">
                            <input
                                type="text"
                                formControlName="country"
                                placeholder="Select Country*"
                                (input)="filterCountries($event)"
                                (focus)="showCountryList = true"
                            />
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('country')"
                            >
                                Please select a country
                            </div>

                            <div
                                class="country-list"
                                *ngIf="
                                    showCountryList &&
                                    filteredCountries.length > 0
                                "
                            >
                                <div
                                    class="country-item"
                                    *ngFor="let country of filteredCountries"
                                    (mousedown)="selectCountry(country)"
                                >
                                    {{ country }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- How did you hear about us -->
                    <div
                        class="form-group"
                        [class.invalid]="isFieldInvalid('message')"
                    >
                        <textarea
                            formControlName="message"
                            placeholder="Your Message"
                        ></textarea>
                        <div
                            class="error-message"
                            *ngIf="isFieldInvalid('message')"
                        >
                            Message cannot exceed 500 characters
                        </div>
                    </div>

                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey">
                    </ngx-recaptcha2>
                    <div class="form-error-message" *ngIf="showFormError">
                        Please complete all required fields.
                    </div>
                    <button type="submit" class="submit-btn">SUBMIT</button>
                </form>
            </div>
        </div>
    </div>
</div>

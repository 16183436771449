import { Component } from '@angular/core';

@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.scss']
})
export class AdvanceComponent {
  services: IgnitionService[] = [
    
    {
        id: 1,
        title: 'End-to-End Ignition Solution Design',
        description: 'Complete system architecture and implementation for enterprise-scale deployments.',
        icon: 'fas fa-project-diagram',
        bulletPoints: [
            'Comprehensive architecture for distributed or redundant Ignition Gateways.',
            'Multi-site deployment with centralized monitoring and control.',
            
        ]
    },
    {
        id: 2,
        title: 'Advanced IIoT Implementation',
        description: 'Sophisticated IIoT solutions for complex industrial environments.',
        icon: 'fas fa-network-wired',
        bulletPoints: [
            'Deploying large-scale MQTT infrastructure with Ignition Edge for edge device management.',
            'Real-time, bidirectional data exchange between Ignition and enterprise systems like ERP, MES, or CMMS.',

        ]
    },
    {
        id: 3,
        title: 'Complex SCADA Development',
        description: 'Development of sophisticated SCADA systems for critical operations.',
        icon: 'fas fa-laptop-code',
        bulletPoints: [
            'Designing and deploying custom SCADA projects for process industries (e.g., oil & gas, food & beverage, pharmaceuticals).',
            ' Integration of advanced analytics and machine learning for predictive maintenance or process optimization.',
           
        ]
    },
    {
        id: 4,
        title: 'Advanced Historian Setup & Data Analytics',
        description: 'Sophisticated data collection and analysis solutions for enterprise needs.',
        icon: 'fas fa-chart-line',
        bulletPoints: [
            'Configuring Ignition with cloud services (AWS, Azure, Google Cloud) for big data storage and analytics.',
            'Creating custom analytics dashboards and reports using scripting and external tools (e.g., Python or Power BI).',
        ]
    },
    {
        id: 5,
        title: 'Redundancy & High Availability',
        description: 'Implementation of fault-tolerant systems for critical operations.',
        icon: 'fas fa-shield-alt',
        bulletPoints: [
            'Setting up redundant Gateways to ensure uninterrupted operations.',
            'Disaster recovery planning and testing. ',
          
        ]
    },
    {
        id: 6,
        title: 'Custom IIoT Applications',
        description: 'Development of specialized IIoT applications for unique industrial needs.',
        icon: 'fas fa-microchip',
        bulletPoints: [
            'Developing bespoke IIoT solutions using Ignition’s Perspective module for mobile and web-friendly apps.',
            'Integration of AI-driven insights using external APIs.',

        ]
    },
    {
        id: 7,
        title: 'Training & Support for Advanced Teams',
        description: 'Comprehensive training and support for advanced technical teams.',
        icon: 'fas fa-users-cog',
        bulletPoints: [
            'Training engineering teams on Ignition’s advanced modules (e.g., Perspective, WebDev, and MQTT Transmission).',
            'On-demand workshops and certifications for in-house developers. ',

        ]
    },
    {
        id: 8,
        title: 'System Optimization & Maintenance',
        description: 'Continuous optimization and maintenance of complex systems.',
        icon: 'fas fa-tools',
        bulletPoints: [
            'SLA-based 24/7 technical support for enterprise-grade solutions.',
            'Proactive system monitoring and maintenance',
            'Proactive system monitoring, updates, and scalability enhancements.'
        ]
    }
];
}

interface IgnitionService {
id: number;
title: string;
description: string;
icon: string;
bulletPoints?: string[];
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-interimediate',
  templateUrl: './interimediate.component.html',
  styleUrls: ['./interimediate.component.scss']
})
export class InterimediateComponent {
  services: IgnitionService[] = [
    {
        id: 1,
        title: 'Custom SCADA Dashboards',
        description: 'Professional development of tailored SCADA interfaces that perfectly match your operational needs.',
        icon: 'fas fa-desktop',
        bulletPoints: [
            'Developing process-specific dashboards with real-time visualization for KPIs like OEE, machine states, or production rates.',
            'Building user-friendly HMIs (Human-Machine Interfaces) using Ignition’s Vision or Perspective modules.',
        ]
    },
    {
        id: 2,
        title: 'IIoT Device Integration',
        description: 'Seamless integration of Industrial IoT devices into your existing infrastructure.',
        icon: 'fas fa-microchip',
        bulletPoints: [
            'Configuring Ignition Edge for edge computing and data collection from IoT sensors.',
            'Enabling data streams over MQTT Sparkplug B for efficient and lightweight communication.',
        ]
    },
    {
        id: 3,
        title: 'Database Integration & Historian Setup',
        description: 'Advanced database configuration and historical data management solutions.',
        icon: 'fas fa-database',
        bulletPoints: [
            'Connecting Ignition to SQL databases (MySQL, PostgreSQL, etc.) for historical data logging.',
         'Implementing Ignition’s Tag Historian module for seamless data storage and retrieval.'
        ]
    },
    {
        id: 4,
        title: 'Custom Scripting & Automation',
        description: 'Development of sophisticated automation scripts and custom functionality.',
        icon: 'fas fa-code',
        bulletPoints: [
            'Python scripting for advanced data manipulation, event handling, and automating workflows within Ignition.',
            'Configuring automated report generation using the Reporting module.',
        ]
    },
    {
        id: 5,
        title: 'Industrial Protocol Integration',
        description: 'Expert integration of various industrial protocols for seamless communication.',
        icon: 'fas fa-exchange-alt',
        bulletPoints: [
            ' Advanced configuration for protocols like BACnet, DNP3, and IEC 60870 for specific industries.',
           
        ]
    },
    {
        id: 6,
        title: 'Security Hardening',
        description: 'Implementation of robust security measures to protect your industrial systems.',
        icon: 'fas fa-shield-alt',
        bulletPoints: [
            'Implementing role-based security and encrypted communication between Ignition servers, clients, and devices.'
        ]
    },
    {
        id: 7,
        title: 'Team Training',
        description: 'Comprehensive training programs for advanced Ignition features and development.',
        icon: 'fas fa-users',
        bulletPoints: [
            'Intermediate training sessions on Ignition’s Designer, Gateway, and Tag systems.',
            'Hands-on exercises for developing custom SCADA or IIoT projects.',
        ]
    }
];
}

interface IgnitionService {
id: number;
title: string;
description: string;
icon: string;
bulletPoints?: string[];
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-add-on-service',
  templateUrl: './add-on-service.component.html',
  styleUrls: ['./add-on-service.component.scss']
})





export class AddOnServiceComponent {
  services: Service[] = [


    {
      title: "Common Platform Features",
      description: "Essential features available across all service levels to enhance your experience.",
      icon: "fas fa-puzzle-piece",
      bulletPoints: [
        "User Authentication & Profiles with role-based access control",
        "Service Marketplace with categorized browsing",
        "Communication Hub with chat, video calls, and notifications",
        "Learning Zone featuring tutorials, webinars, and certifications",
        "Project Dashboard for tracking progress and milestones"
      ]
    },

    {
      title: "Remote Support & Management",
      description: "Comprehensive remote assistance and system management services to ensure your Ignition deployment runs smoothly.",
      icon: "fas fa-desktop",
      bulletPoints: [
        "24/7 remote troubleshooting support",
        "Dedicated account manager for personalized assistance",
        "Regular system health check-ups",
        "Optional on-site technical support"
      ]
    },
    {
      title: "Licensing & Hardware Solutions",
      description: "Expert guidance on Ignition licensing and hardware infrastructure to optimize your deployment.",
      icon: "fas fa-server",
      bulletPoints: [
        "Strategic license management and upgrade planning",
        "Hardware recommendations for edge gateways",
        "IIoT device integration consulting",
        "SCADA hardware specification assistance"
      ]
    },
    {
      title: "Advanced Technical Services",
      description: "In-depth technical support and integration services for complex Ignition deployments.",
      icon: "fas fa-code-branch",
      bulletPoints: [
        "Root-cause analysis for complex issues",
        "Performance optimization",
        "Custom API development",
        "Third-party platform integration"
      ]
    }
  ];
}


interface Service {
  title: string;
  description: string;
  icon: string;
  bulletPoints: string[];
}
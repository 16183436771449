import { Component } from '@angular/core';







@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent {
  services: IgnitionService[] = [
      {
          id: 1,
          title: 'Ignition Installation & Licensing Assistance',
          description: 'Professional installation services and licensing guidance to get your Ignition system up and running smoothly.',
          icon: 'fas fa-download',
          bulletPoints: [
              'Help with downloading, installing, and setting up the Ignition platform (Maker, Standard, or Edge)',
              'Download, Install, and Set Up Ignition Platform (Maker, Standard, or Edge)',
              'Guidance on selecting the appropriate licensing model (modules like Perspective, Vision, or Edge)'
          ]
      },
      {
          id: 2,
          title: 'Basic System Architecture Design',
          description: 'Expert consultation on designing scalable and efficient system architectures tailored to your needs.',
          icon: 'fas fa-sitemap',
          bulletPoints: [
              'High-level guidance on designing an architecture (single server vs. distributed system).',
              'Recommendations for hardware and network configurations.',
          ]
      },
      {
          id: 3,
          title: 'Connecting to Devices',
          description: 'Seamless integration services for connecting Ignition with your industrial devices and equipment.',
          icon: 'fas fa-plug',
          bulletPoints: [
              'Step-by-step help in connecting PLCs, RTUs, and edge devices using protocols like OPC-UA, MQTT, or Modbus.',
              'Setting up a Gateway to collect and process data.',
          ]
      },
      {
          id: 4,
          title: 'Ignition Starter Training',
          description: 'Comprehensive training programs to help your team master the fundamentals of Ignition.',
          icon: 'fas fa-graduation-cap',
          bulletPoints: [
              'Ignition Basics Training ',
              'Overview of Ignition’s Designer interface.',
              'Creating simple tags, building a basic dashboard, and configuring alarms.'
          ]
      },
      {
          id: 5,
          title: 'Basic Troubleshooting & Support',
          description: 'Expert technical support and troubleshooting services to keep your Ignition system running optimally.',
          icon: 'fas fa-wrench',
          bulletPoints: [
              'Live chat, email, or call support for setup-related issues.',
              'Assistance with common error resolutions and log analysis.',
              
          ]
      },
      {
          id: 6,
          title: 'Access to Resources',
          description: 'Exclusive access to documentation, best practices, and learning materials to support your Ignition journey.',
          icon: 'fas fa-book',
          bulletPoints: [
              'User manuals, video tutorials, and webinars covering IIoT and SCADA fundamentals with Ignition',
              
          ]
      }
  ];
}


interface IgnitionService {
  id: number;
  title: string;
  description: string;
  icon: string;
  bulletPoints?: string[];
}

<div class="trial-container">
    <div class="left-content">
        <h1 class="main-heading">FREE TRIAL<br />PROGRAM</h1>
        <h2 class="sub-heading">
            TRY <span style="color: #1d9395">Lean</span
            ><span style="color: #da5c30">Q</span
            ><span style="color: #1d9395">ubit</span> FREE BEFORE YOU BUY IT.
        </h2>

        <ul class="features-list">
            <li>Easy to install and get started</li>
            <li>Gain actionable insights from real-time data</li>
            <li>Deliver powerful use cases that drive rapid growth</li>
        </ul>

        <button class="trial-button" (click)="scrollToTrial()">
            REQUEST YOUR TRIAL NOW
        </button>

        <div class="companies-section">
            <h3 class="companies-heading">
                Discover Leading Products for Manufacturers Like You.
            </h3>
            <div class="company-logos">
                <div class="company-item" *ngFor="let company of companies">
                    <img
                        [src]="company.image"
                        [alt]="company.name"
                        class="company-logo"
                    />
                    <p class="company-title">{{ company.name }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="right-content">
        <div class="dashboard-image">
            <img src="assets\img\OptebizImage\PDM.png" alt="PDM Dashboard" />
        </div>
    </div>
</div>
<!-- Add this section How its work -->
<div class="how-it-works">
    <div class="how-it-works-container">
        <div class="device-image">
            <img
                src="assets\img\OptebizImage\inductive flow2.svg"
                alt="Device"
            />
        </div>
        <div class="steps-content">
            <h2 class="section-title">HOW IT WORKS</h2>
            <ul class="steps-list">
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#e3f2fd"
                                stroke="#2196f3"
                                stroke-width="2"
                            />
                            <path
                                d="M30 40 L50 30 L70 40 L70 60 L50 70 L30 60Z"
                                fill="#2196f3"
                                stroke="#1565c0"
                                stroke-width="2"
                            />
                            <circle cx="50" cy="50" r="10" fill="#1565c0" />
                            <path
                                d="M40 35 L60 35 M40 65 L60 65"
                                stroke="#1565c0"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>RESOURCE MANAGEMENT</h3>
                        <p>
                            Connect your equipment, materials, and personnel
                            within a unified system that monitors all your
                            manufacturing resources in real-time.
                        </p>
                    </div>
                </li>
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#e8f5e9"
                                stroke="#4caf50"
                                stroke-width="2"
                            />
                            <path
                                d="M25 50 L45 30 M45 30 L65 30 M65 30 L75 50 M75 50 L65 70 M65 70 L45 70 M45 70 L25 50"
                                fill="none"
                                stroke="#4caf50"
                                stroke-width="3"
                            />
                            <circle cx="25" cy="50" r="8" fill="#2e7d32" />
                            <circle cx="45" cy="30" r="8" fill="#2e7d32" />
                            <circle cx="65" cy="30" r="8" fill="#2e7d32" />
                            <circle cx="75" cy="50" r="8" fill="#2e7d32" />
                            <circle cx="65" cy="70" r="8" fill="#2e7d32" />
                            <circle cx="45" cy="70" r="8" fill="#2e7d32" />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>PROCESS INTEGRATION</h3>
                        <p>
                            how your production lines, processes, and
                            capabilities interact through comprehensive
                            monitoring and data collection.
                        </p>
                    </div>
                </li>
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#fff3e0"
                                stroke="#ff9800"
                                stroke-width="2"
                            />
                            <path
                                d="M30 50 L45 35 L60 50 L45 65Z"
                                fill="#ff9800"
                            />
                            <path
                                d="M50 20 L70 40 L50 60 L30 40Z"
                                fill="none"
                                stroke="#ef6c00"
                                stroke-width="3"
                            />
                            <circle cx="50" cy="40" r="5" fill="#ef6c00" />
                            <path
                                d="M40 70 L60 70"
                                stroke="#ef6c00"
                                stroke-width="3"
                                stroke-linecap="round"
                            />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>OPERATIONAL EXCELLENCE</h3>
                        <p>
                            Take action by managing operations efficiently,
                            tracking downtime, and ensuring production quality
                            through sequential operational steps.
                        </p>
                    </div>
                </li>
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#e1f5fe"
                                stroke="#03a9f4"
                                stroke-width="2"
                            />
                            <path
                                d="M30 70 L30 40 L45 40 L45 70 Z"
                                fill="#03a9f4"
                            />
                            <path
                                d="M50 70 L50 30 L65 30 L65 70 Z"
                                fill="#03a9f4"
                            />
                            <path
                                d="M25 75 L75 75"
                                stroke="#0288d1"
                                stroke-width="3"
                            />
                            <circle cx="70" cy="35" r="8" fill="#0288d1" />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>REPORTING & INSIGHTS</h3>
                        <p>
                            Scale your understanding through detailed reports
                            that combine material quality metrics, production
                            data, and operational performance.
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="why-trial">
    <div class="why-trial-container">
        <div class="left-content">
            <h2 class="section-titleLQ">
                WHY TRIAL <span style="color: #1d9395">Lean</span
                ><span style="color: #da5c30">Q</span>
                <span style="color: #1d9395">ubit</span>?
            </h2>
            <p class="section-description">
                See for yourself how easy it is to self-install and drive
                actionable insights with our intuitive, flexible production
                monitoring platform. Deliver powerful use cases to your team in
                a matter of minutes that go right to your bottom line.
            </p>
        </div>
        <div class="right-content">
            <div class="trial-image">
                <img
                    src="assets/img/OptebizImage/SSD.png"
                    alt="Trial MM Dashboard"
                />
            </div>
        </div>
    </div>

    <div class="benefits-container">
        <div class="benefit-item">
            <div class="benefit-icon">
                <svg viewBox="0 0 24 24" fill="#1d9395" width="50" height="50">
                    <g transform="translate(2, 6)">
                        <!-- Top line -->
                        <rect x="0" y="2" width="20" height="2" />
                        <!-- Bottom line -->
                        <rect x="0" y="8" width="20" height="2" />
                    </g>
                </svg>
            </div>
            <h3>EXPERIENCE THE DIFFERENCE</h3>
            <p>
                Our production monitoring platform is the top choice for
                manufacturers.
            </p>
        </div>

        <div class="benefit-item">
            <div class="benefit-icon">
                <svg viewBox="0 0 24 24" fill="#1d9395" width="50" height="50">
                    <path d="M13 2L4 14h7l-2 8 9-12h-7l2-8z" />
                </svg>
            </div>
            <h3>TRY BEFORE YOU BUY</h3>
            <p>
                Gain proof that our solution will deliver quantifiable value for
                your factory floor.
            </p>
        </div>

        <div class="benefit-item">
            <div class="benefit-icon">
                <svg viewBox="0 0 24 24" fill="#1d9395" width="50" height="50">
                    <path
                        d="M12 6c0-2.2-1.8-4-4-4S4 3.8 4 6s1.8 4 4 4 4-1.8 4-4z"
                    />
                    <path
                        d="M16 8h4M18 6v4"
                        stroke="#1d9395"
                        stroke-width="2.5"
                    />
                    <path
                        d="M8 12H4c-2.2 0-4 1.8-4 4v2h16v-2c0-2.2-1.8-4-4-4H8z"
                    />
                    <path
                        d="M20 16h4M22 14v4"
                        stroke="#1d9395"
                        stroke-width="2.5"
                    />
                </svg>
            </div>
            <h3>BASELINE YOUR COMPANY</h3>
            <p>
                Get real-time insights into what's actually happening on your
                shop floor.
            </p>
        </div>

        <div class="benefit-item">
            <div class="benefit-icon">
                <svg viewBox="0 0 24 24" fill="#1d9395" width="50" height="50">
                    <circle cx="12" cy="12" r="3" />
                    <circle cx="4" cy="12" r="2" />
                    <circle cx="20" cy="12" r="2" />
                    <circle cx="12" cy="4" r="2" />
                    <circle cx="12" cy="20" r="2" />
                    <line
                        x1="12"
                        y1="7"
                        x2="12"
                        y2="17"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <line
                        x1="6"
                        y1="12"
                        x2="18"
                        y2="12"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                </svg>
            </div>
            <h3>SOLVE PILOT PURGATORY</h3>
            <p>
                Walk away with rapid ROI driving use cases and a roadmap for
                implementation.
            </p>
        </div>
    </div>
</div>

<!-- fourth section -->
<!-- <div class="qualification-section">
    <div class="qualification-container">
        <div class="laptop-content">
            <img
                src="assets/img/OptebizImage/SSD.png"
                alt="Qualification Dashboard"
                class="laptop-image"
            />
            <p class="qualification-note">
                Don't meet all the criteria? No problem, talk to our team about
                other ways we can work together.
            </p>
        </div>
        <div class="qualification-content">
            <h1 class="qualification-title">DO YOU QUALIFY?</h1>
            <p class="qualification-description">
                Only certain machine types qualify for our program (modern CNC
                machines). Find out if your organization is a fit for our trial
                program:
            </p>
            <ul class="qualification-list">
                <li>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="12" cy="12" r="11" fill="#1d9395" />
                        <path
                            d="M7 12.5L10.5 16L17 8"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <strong>25+ MACHINES</strong> in your facility
                </li>
                <li>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="12" cy="12" r="11" fill="#1d9395" />
                        <path
                            d="M7 12.5L10.5 16L17 8"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <strong>CLOUD/SAAS</strong> is not a problem
                </li>
                <li>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="12" cy="12" r="11" fill="#1d9395" />
                        <path
                            d="M7 12.5L10.5 16L17 8"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <strong>CHAMPION</strong> has been identified
                </li>
                <li>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="12" cy="12" r="11" fill="#1d9395" />
                        <path
                            d="M7 12.5L10.5 16L17 8"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <strong>EXECUTIVE</strong> SPONSOR is bought in
                </li>
                <li>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="12" cy="12" r="11" fill="#1d9395" />
                        <path
                            d="M7 12.5L10.5 16L17 8"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <strong>TIMELINE</strong> has been agreed upon
                </li>
                <li>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="12" cy="12" r="11" fill="#1d9395" />
                        <path
                            d="M7 12.5L10.5 16L17 8"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <strong>BUSINESS</strong> CASE is valid and aligned
                </li>
            </ul>
        </div>
    </div>
</div> -->

<!-- new section -->
<div class="trial-page" #trialSection>
    <div class="trial-containernew">
        <div class="trial-content">
            <h1 class="trial-title">REQUEST YOUR<br />FREE TRIAL</h1>

            <div class="trial-description">
                <p>
                    Discover why hundreds of manufacturers have linked thousands
                    of machines to LeanQubit.
                </p>

                <ul class="features-listnew">
                    <li>Easily and quickly on 1-2 machines</li>
                    <li>Capture and baseline real-time data</li>
                    <li>Prove out LeanQubit before scaling</li>
                </ul>
            </div>
        </div>

        <div class="form-container">
            <div class="form-header">
                <div
                    *ngIf="currentStep === 2"
                    class="back-button"
                    (click)="onBack()"
                >
                    <i class="fas fa-arrow-left"></i>
                </div>
                <div class="step-indicator">
                    {{ currentStep }}/{{ totalSteps }}
                </div>
            </div>

            <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                <!-- Step 1 -->
                <div *ngIf="currentStep === 1" formGroupName="step1">
                    <h2>Tell us about yourself</h2>
                    <p>
                        Please fill out the below contact information to begin
                        your application
                    </p>

                    <div class="form-row">
                        <div class="form-group">
                            <input
                                type="text"
                                formControlName="firstName"
                                placeholder="First name*"
                            />
                            <div class="error-message">
                                {{ getErrorMessage("firstName") }}
                            </div>
                        </div>
                        <div class="form-group">
                            <input
                                type="text"
                                formControlName="lastName"
                                placeholder="Last name*"
                            />
                            <div class="error-message">
                                {{ getErrorMessage("lastName") }}
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group">
                            <input
                                type="email"
                                formControlName="email"
                                placeholder="Email*"
                            />
                            <div class="error-message">
                                {{ getErrorMessage("email") }}
                            </div>
                        </div>
                        <div class="form-group">
                            <input
                                type="tel"
                                formControlName="phone"
                                placeholder="Phone number*"
                            />
                            <div class="error-message">
                                {{ getErrorMessage("phone") }}
                            </div>
                        </div>
                    </div>

                    <div class="form-group country-input">
                        <input
                            type="text"
                            formControlName="country"
                            placeholder="Search country*"
                            (input)="filterCountries($event)"
                            (focus)="showCountryList = true"
                        />
                        <div class="error-message">
                            {{ getErrorMessage("country") }}
                        </div>

                        <div
                            class="country-list"
                            *ngIf="
                                showCountryList && filteredCountries.length > 0
                            "
                        >
                            <div
                                class="country-item"
                                *ngFor="let country of filteredCountries"
                                (click)="selectCountry(country)"
                            >
                                {{ country }}
                            </div>
                        </div>
                    </div>

                    <button
                        type="button"
                        class="continue-btn"
                        (click)="onContinue()"
                    >
                        CONTINUE
                    </button>
                </div>

                <!-- Step 2 -->
                <div *ngIf="currentStep === 2" formGroupName="step2">
                    <h2>How many machines do you have in your plant/plants?</h2>

                    <div class="radio-group">
                        <label>
                            <input
                                type="radio"
                                formControlName="machineCount"
                                value="<25"
                            />
                            < 25
                        </label>
                        <label>
                            <input
                                type="radio"
                                formControlName="machineCount"
                                value="25-50"
                            />
                            25-50
                        </label>
                        <label>
                            <input
                                type="radio"
                                formControlName="machineCount"
                                value="50-100"
                            />
                            50-100
                        </label>
                        <label>
                            <input
                                type="radio"
                                formControlName="machineCount"
                                value="100-200"
                            />
                            100-200
                        </label>
                        <label>
                            <input
                                type="radio"
                                formControlName="machineCount"
                                value="200+"
                            />
                            200+
                        </label>
                    </div>
                    <div class="error-message">
                        {{ getErrorMessage("machineCount", "step2") }}
                    </div>

                    <div class="form-group">
                        <h2>How did you hear about us?</h2>
                        <textarea
                            formControlName="hearAboutUs"
                            placeholder="Where did you hear about us?"
                        ></textarea>
                        <div class="error-message">
                            {{ getErrorMessage("hearAboutUs", "step2") }}
                        </div>
                    </div>

                    <button type="submit" class="submit-btn">SUBMIT</button>
                </div>
            </form>
        </div>
    </div>
</div>

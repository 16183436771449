import { Component, OnInit } from '@angular/core';



interface BannerContent {
  title: string;
  paragraphText: string;
  defaultBtnLink: string;
  defaultBtnIcon: string;
  defaultBtnText: string;
  videoBtnLink: string;
  videoBtnIcon: string;
  videoBtnText: string;
}

@Component({
  selector: 'app-testing-mainbanner',
  templateUrl: './testing-mainbanner.component.html',
  styleUrls: ['./testing-mainbanner.component.scss']
})
export class TestingMainbannerComponent implements OnInit {

  currentCardIndex = 0;
  isAnimating = true;
  animationTimeout: any;
  currentIndex = 0; 

  cards = [
    // Top Row Cards
    {
      id: 'top-card-1',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoMES',
        description: 'Monitor and optimize shop floor workflows from order to completion.',
        iconUrl: 'assets/img/OptebizImage/FactoMES.svg'
      }
    },
    {
      id: 'top-card-2',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoIQ',
        description: 'Analyze shop floor processes using real-time data, AI, and statistics.',
        iconUrl: 'assets/img/OptebizImage/Asset 6@36x.png'
      }
    }, 
    {
      id: 'top-card-3',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoCloudConnect',
        description: 'Integrate real-time shop floor data with cloud platforms.',
        iconUrl: 'assets/img/OptebizImage/Asset 4@36x.png'
      }
    },
    {
      id: 'top-card-4',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoLake',
        description: 'Track KPIs to optimize manufacturing efficiency.',
        iconUrl: 'assets/img/OptebizImage/factoLake 2.png'
      }
    },
    {
      id: 'top-card-5',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoEye',
        description: 'Ensure SOP compliance with real-time monitoring.',
        iconUrl: 'assets/img/OptebizImage/FatocEye 2.png'
      }
    },
    // Bottom Row Cards
    {
      id: 'bottom-card-1',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoOEE',
        description: 'FactoOEE provides manufacturers insights into production processes.',
        iconUrl: 'assets/img/OptebizImage/FactoOEE 1.png'
      }
    },
    {
      id: 'bottom-card-2',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoQual',
        description: 'FactoQual is IoT-powered quality control systems and a real-time monitoring tool.',
        iconUrl: 'assets/img/OptebizImage/factoQual 1.png'
      }
    },
    {
      id: 'bottom-card-3',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoSPC',
        description: 'FactoSPC prevents defects, reduces waste, and boosts efficiency',
        iconUrl: 'assets/img/OptebizImage/factoMPCP.png'
      }
    },
    {
      id: 'bottom-card-4',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoMPCP',
        description: 'FactoMPCP monitors and controls production to ensure efficient operations.',
        iconUrl: 'assets/img/OptebizImage/factoMPCP.png'
      }
    },
    {
      id: 'bottom-card-5',
      baseColor: 'white',
      zoomColor: '#e3f2fd',
      data: {
        title: 'FactoPlan',
        description: 'LeanQubit FactoPlan optimizes resource allocation',
        iconUrl: 'assets/img/OptebizImage/factoMPCP.png'
      }
    }
  ];
  mainBannerContent: BannerContent[] = [
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Transform your manufacturing operations with our cutting-edge Industry 4.0 solutions.',
      defaultBtnLink: 'contact',
      defaultBtnIcon: 'bx bxs-contact',
      defaultBtnText: 'Get Started',
      videoBtnLink: '#play-video',
      videoBtnIcon: 'bx bx-play',
      videoBtnText: 'Watch Video'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Optimize your production with real-time monitoring and advanced analytics.',
      defaultBtnLink: 'solutions',
      defaultBtnIcon: 'bx bx-cube',
      defaultBtnText: 'Explore Solutions',
      videoBtnLink: '#demo-video',
      videoBtnIcon: 'bx bx-play-circle',
      videoBtnText: 'Watch Demo'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
    {
      title: 'Manufacturing Excellence Through Digital Innovation',
      paragraphText: 'Seamlessly connect your shop floor with cloud-based intelligence.',
      defaultBtnLink: 'services',
      defaultBtnIcon: 'bx bx-network-chart',
      defaultBtnText: 'Learn More',
      videoBtnLink: '#overview-video',
      videoBtnIcon: 'bx bx-video',
      videoBtnText: 'See Overview'
    },
  ];


  ngOnInit() {
    this.startContinuousAnimation();
  }


   // Helper methods for title animation
   getFirstWord(title: string): string {
    return title.split(' ')[0];
  }

  getRemainingWords(title: string): string {
    return title.split(' ').slice(1).join(' ');
  }

  startContinuousAnimation() {
    const animate = async () => {
      if (!this.isAnimating) return;

      if (this.animationTimeout) {
        clearTimeout(this.animationTimeout);
      }

      this.animationTimeout = setTimeout(() => {
        this.currentCardIndex = (this.currentCardIndex + 1) % this.cards.length;
        this.currentIndex = (this.currentIndex + 1) % this.mainBannerContent.length;
        requestAnimationFrame(() => animate());
      }, 5000);
    };

    animate();
  }

  onCardClick(cardIndex: number, isBottomRow: boolean) {
    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout);
    }

    const actualIndex = isBottomRow ? cardIndex + 5 : cardIndex;
    this.currentCardIndex = actualIndex;
    this.currentIndex = actualIndex % this.mainBannerContent.length;

    setTimeout(() => {
      this.currentCardIndex = (actualIndex + 1) % this.cards.length;
      this.currentIndex = (this.currentIndex + 1) % this.mainBannerContent.length;
      this.startContinuousAnimation();
    }, 5000);
  }

  ngOnDestroy() {
    this.isAnimating = false;
    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout);
    }
  }
}